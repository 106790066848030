import { Link } from "react-router-dom";
import styled from "styled-components";
import { Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";

const Wrapper = styled.div`
  .carousel,
  .carousel-inner,
  .carousel-item {
    border-radius: 1.042vw;
    border: none;
    overflow: hidden;
    height: 100%;
  }

  .carousel-item {
    position: relative;
  }

  .carousel {
    align-items: center;
    display: flex;
  }

  .carousel-control-next,
  .carousel-control-prev {
    bottom: inherit;
    top: inherit;
    height: 2rem;
    width: 2rem;
    color: #f88436;
    font-size: 3.125vw;
    opacity: 1;
  }

  .carousel-control-prev {
    left: 1.563vw;
  }

  .carousel-control-next {
    right: 1.563vw;
  }

  .carousel-item .item__hover {
    opacity: 0;
    transition: 0.5s ease;
    width: 100%;
    padding: 0;
    top: 0;
    left: 0;
  }

  .imagem-fundo {
    background-position: center center;
    background-size: cover !important;
    position: relative;

    .image-link {
      left: 0;
      top: 0;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  .imagem-div {
    background: rgb(12, 17, 81);
    background: linear-gradient(
      180deg,
      rgba(12, 17, 81, 0) 0%,
      rgba(12, 17, 81, 1) 70%
    );
    height: 100%;
  }

  .imagem-fundo:hover .imagem-div {
    background: rgb(248, 132, 54);
    background: linear-gradient(
      180deg,
      rgba(248, 132, 54, 0) 0%,
      rgba(248, 132, 54, 1) 70%
    );
  }

  .carousel-item:hover .item__hover {
    opacity: 1;
  }

  .item__content {
    position: absolute;
    bottom: 0;
    left: 0;
    text-decoration: none;
    text-transform: uppercase;
    text-align: left;
    transition: 0.3s;
    padding: 1.302vw 1.6666vw;
    width: 100%;

    .title {
      color: #ffffff;
      border-bottom: 0.2604vw solid #ffffff;
      display: inline-block;
      padding-bottom: 1px;
      font-family: "Fira Sans", sans-serif;
      font-size: 2.083vw;
      font-weight: bold;
      line-height: 2.5vw;
    }

    .text {
      font-family: "Fira Sans", sans-serif;
      font-size: 2.0604vw;
      font-weight: bold;
      line-height: 3.3333vw;
      letter-spacing: 0px;
      color: #f88436;
      transition: 0.3s ease;

      b {
        font-size: 4.218vw;
        line-height: 5.05208vw;
      }
    }
  }

  .carousel-item:hover .item__content .text {
    color: #0c1151;
  }

  @media (max-width: 992px) {
    .carousel,
    .carousel-inner,
    .carousel-item {
      border-radius: 4.167vw;
      height: 50vh;
    }

    .carousel-control-next,
    .carousel-control-prev {
      font-size: 24px;
    }

    .item__content {
      padding: 20px 30px;

      .title {
        border-bottom: 2px solid #ffffff;
        font-size: 22px;
        line-height: 26px;
        padding-bottom: 5px;
      }

      .text {
        font-size: 14px;
        line-height: 18px;

        b {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .item__content {
      .title {
        font-size: 40px;
        line-height: 48px;
      }

      .text {
        font-size: 45px;
        line-height: 64px;

        b {
          font-size: 81px;
          line-height: 97px;
        }
      }
    }

    .carousel-item .item__hover,
    .carousel-item img {
      height: 100%;
    }
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  transition: 0.5s ease;
`;

export default function HomeCarousel() {
  const [banners, setBanners] = useState([]);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 992px)").matches
  );

  const getBannersOrder = (banners) => {
    let spapBanners = banners?.filter((banner) => {
      if (banner.type == 0) return banner;
    });
    spapBanners.sort((a, b) => a.ordem - b.ordem);
    return spapBanners;
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-banners`, {
      method: "GET", // or 'POST', etc.
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar banners");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        console.log(data.banners);
        setBanners(() => getBannersOrder(data.banners));
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Wrapper>
      <Carousel
        indicators={false}
        prevIcon={
          banners.length == 1 ? "" : <FontAwesomeIcon icon={faChevronLeft} />
        }
        nextIcon={
          banners.length == 1 ? "" : <FontAwesomeIcon icon={faChevronRight} />
        }
      >
        {banners.map((item, index) => {
          return (
            <Carousel.Item
              className={"imagem-fundo item item-" + index}
              style={
                !matches && item.imagem_hover != null
                  ? {
                      background:
                        "url(https://api.spap.pt/banners/" +
                        item.imagem_hover +
                        ")",
                    }
                  : {
                      background:
                        "url(https://api.spap.pt/banners/" + item.imagem + ")",
                    }
              }
            >
              {(item.titulo || item.descricao) && (
                <div className="imagem-div">
                  {/* <Link to={item.link}>
                <picture>
                  <source srcSet={item.desktop} media="(min-width: 990px)" />
                  <CarouselImage src={item.mobile} alt={item.alt} />
                </picture>
              </Link> */}
                  {/* <Carousel.Caption className="item__hover">
                <Link to={item.link}>
                  <picture>
                    <source srcSet={item.desktopHover} media="(min-width: 990px)" />
                    <CarouselImage src={item.mobileHover} alt={item.alt} />
                  </picture>
                </Link>
              </Carousel.Caption> */}

                  <Space direction="vertical" className="item__content">
                    {item.titulo && <h3 className="title">{item.titulo}</h3>}
                    {item.descricao && (
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{ __html: item.descricao }}
                      ></div>
                    )}
                  </Space>
                </div>
              )}
              <Link to={item.link} className="image-link"></Link>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Wrapper>
  );
}
