import styled from "styled-components";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";

import CardApresentacoes from "../../../Components/Backoffice/CardApresentacoes";
import CardEmails from "../../../Components/Backoffice/CardEmails";

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

export default function Emails(props) {
  document.title = "SPAP - Emails";
  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <CardEmails />
        <Footer />
      </Content>
    </Wrapper>
  );
}
