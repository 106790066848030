import styled from "styled-components";
import { Helmet } from "react-helmet";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  .content__text {
    max-width: 62.5vw;
    margin-right: auto;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .content__text {
      max-width: 100%;
    }
  }
`;

const SectionPartners = styled.section`
  display: flex;
  flex-direction: column;

  > :nth-child(odd) {
    background-color: #ffffff;
  }

  > :nth-child(even) {
    background-color: #d6d7e2;
  }

  .item {
    padding: 2.083vw 0;
  }

  .item__content {
    display: grid;
    grid-template-columns: 15.625vw 1fr;
    gap: 2.604vw;
  }

  .item__text {
    place-self: center flex-start;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 992px) {
    .item__content {
      grid-template-columns: 1fr;

      .item__image {
        margin: 20px auto;
        width: 200px;
      }
    }

    .item__text {
      font-size: 18px;
      line-height: 24px;

      * {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  @media (min-width: 1920px) {
    .item__text {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const SectionProjects = styled.section`
  padding: 5.208vw 0;

  .title {
    text-align: left;
    font-size: 1.25vw;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }

  .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .item {
    max-width: 56.667vw;
  }

  .image {
    width: 6.458vw;
  }

  .text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  .text b {
    font-weight: bold;
  }

  @media (max-width: 992px) {
    .text {
      font-size: 16px;
      line-height: 22px;
    }

    .title {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (min-width: 1920px) {
    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export default function ParceriasProjetos(props) {
  const partners = [
    {
      imageDesktop: require("../../../Assets/Images/img-rodape-parceiros-03.png"),
      imageMobile: require("../../../Assets/Images/img-rodape-parceiros-03.png"),
      imageAlt: "ID BRCA 01",
      link: "https://www.idbrca.com/prostate-home.html",
      text: 'A Sociedade Portuguesa de Anatomia Patológica disponibiliza com o apoio da AstraZeneca e MSD, o acesso a um número limitado de testes BRCA 1/2 para casos de carcinoma da próstata metastizado, hormonossensível ou resistente a castração e/ou de alto risco (≥T3; Gleason score ≥8; PSA ≥20) e/ou familiar em doentes com idade <60 anos, realizadas no enquadramento das normas legais vigentes1,2. <br/>Os testes seguem as recomendações nacionais para a realização da pesquisa em cancro da próstata e serão disponibilizados através de laboratório nacional certificado para testes genéticos.  <br/><br/>Para mais informações contactar: <a href="mailto:geral@spap.phormuladev.com">geral@spap.phormuladev.com</a>  <br/><br/><span style="font-size: 12px">Referências: 1. Decreto-Lei n.0 131 /2014; 2. Lei n.0 12/2005. I Este material foi revisto e aprovado pela AZ e MSD enquanto material de promoção e educação médica PT-12580 aprovado a 04/2022 .</span> ',
    },
    {
      imageDesktop: require("../../../Assets/Images/img-rodape-parceiros-02.png"),
      imageMobile: require("../../../Assets/Images/img-rodape-parceiros-02.png"),
      imageAlt: "ID BRCA 02",
      link: "https://www.idbrca.com/ovarian-home.html",
      text: 'A Sociedade Portuguesa de Anatomia Patológica disponibiliza com o apoio da AstraZeneca e MSD, o acesso a um número limitado de testes BRCA 1/2 para casos de cancro do ovário epitelial, da trompa e do peritoneu, não mucinoso, realizadas no enquadramento das normas legais vigentes1,2. <br/>Os testes seguem as recomendações nacionais para a realização da pesquisa em cancro do ovário e serão disponibilizados através de laboratório nacional certificado para testes genéticos. <br/><br/>Para mais informações contactar: <a href="mailto:geral@spap.phormuladev.com">geral@spap.phormuladev.com</a>  <br/><br/><span style="font-size: 12px">Referências: 1. Decreto-Lei n.0 131/2014; 2. Lei n.0 12/2005. I Este material foi revisto e aprovado pela AZ e MSD enquanto material de promoção e educação medica. PT-12578 aprovado 04/2022. </span> ',
    },
    {
      imageDesktop: require("../../../Assets/Images/img-rodape-parceiros-04@2x.png"),
      imageMobile: require("../../../Assets/Images/img-rodape-parceiros-04.png"),
      imageAlt: "ONTRK",
      link: "https://ontrk.spap.phormuladev.com/",
      text: "Parceria com a Bayer - <i>“ONTRK-Registo Epidemiológico de Tumores de Fusão NTRK: Prevalência da Translocação NTRK em Portugal”</i>. O estudo compreende o apoio à pesquisa de rearranjos dos genes TRK por NGS em amostras de neoplasias. Os laboratórios que não tenham esta técnica disponível podem contactar o IPATIMUP através do email servicos@ipatimup.pt para conhecerem as caraterísticas do envio.",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Parcerias | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Parcerias com a indústria farmacêutica. A SPAP acolhe parcerias da indústria para dar aos sócios, e aos seus doentes de maneira indireta, oportunidades de acesso a técnicas recentes, com indicação para serem efetuadas no estudo de casos raros, ou que ainda estão em fase pré-clínica. Por favor verifique abaixo as parcerias em vigor e os respetivos contactos para acesso."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Parcerias"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Parcerias",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-o-que-e-anatomia@2x.png")}
          imageMobile={require("../../../Assets/Images/img-o-que-e-anatomia.png")}
          imageAlt="Parcerias"
        />
        <PageText
          className="content__text"
          title="Parcerias com a indústria farmacêutica"
          text="A SPAP acolhe parcerias da indústria para dar aos sócios, e aos seus doentes de maneira indireta, oportunidades de acesso a técnicas recentes, com indicação para serem efetuadas no estudo de casos raros, ou que ainda estão em fase pré-clínica. Por favor verifique abaixo as parcerias em vigor e os respetivos contactos para acesso."
        />
      </Container>

      {partners.length > 0 && (
        <SectionPartners>
          {partners.map((item, index) => {
            return (
              <div className={"item item-" + index}>
                <Container>
                  <div className="item__content">
                    {item.link ? (
                      <a href={item.link} target="_blank">
                        <picture className="item__image">
                          <source
                            srcSet={item.imageDesktop}
                            media="(min-width: 990px)"
                          />
                          <img src={item.imageMobile} alt={item.imageAlt} />
                        </picture>
                      </a>
                    ) : (
                      <picture className="item__image">
                        <source
                          srcSet={item.imageDesktop}
                          media="(min-width: 990px)"
                        />
                        <img src={item.imageMobile} alt={item.imageAlt} />
                      </picture>
                    )}
                    <p className="item__text">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      ></div>
                    </p>
                  </div>
                </Container>
              </div>
            );
          })}
        </SectionPartners>
      )}
      {/*
    #TODO: construir pagina só para projetos após cliente enviar conteudo
      <SectionProjects>
        <Container>
          <FlexInRow gap="1.563vw">
            <FlexInColumn gap="1.042vw">
              <h2 className="title">Projetos em Curso</h2>
              <div className="bar"></div>
            </FlexInColumn>
            <p className="text">Queremos também usar este espaço para fomentar a colaboração entre sócios, que queiram agrupar casos e publicar séries e estudos com uma casuística enriquecida.</p>
            <FlexInRow gap="0" className="item">
              <FlexInColumn gap="1.042vw">
                <picture className="image">
                  <source srcSet={require('../../../Assets/Images/img-logo-parceiros-projetos-05@2x.png')} media="(min-width: 990px)" />
                  <img src={require('../../../Assets/Images/img-logo-parceiros-projetos-05.png')} alt="Project 01" />
                </picture>
              </FlexInColumn>
              <p className="text"><b>Patologista</b>: Raquel Ilgenfritz (<a href="mailto:raquel.ilgenfritz@cuf.pt">raquel.ilgenfritz@cuf.pt</a>)</p>
              <p className="text"><b>Serviço</b>: CUF Descobertas</p>
              <p className="text"><b>Projeto</b>: agrupar casos de <b>sífilis congênita</b>, com ênfase em placenta e achados de autópsia, para revisão e publicação de série de casos. Projeto no âmbito da pós graduação da SOFFOET.</p>
            </FlexInRow>
            <FlexInRow gap="0" className="item">
              <FlexInColumn gap="1.042vw">
                <picture className="image">
                  <source srcSet={require('../../../Assets/Images/img-logo-parceiros-projetos-06@2x.png')} media="(min-width: 990px)" />
                  <img src={require('../../../Assets/Images/img-logo-parceiros-projetos-06.png')} alt="Project 02" />
                </picture>
                <picture className="image">
                  <source srcSet={require('../../../Assets/Images/img-logo-parceiros-projetos-07@2x.png')} media="(min-width: 990px)" />
                  <img src={require('../../../Assets/Images/img-logo-parceiros-projetos-07.png')} alt="Project 03" />
                </picture>
              </FlexInColumn>
              <p className="text"><b>Patologista</b>: Mariana Pereira (<a href="mailto:mariana.pereira@cuf.pt">mariana.pereira@cuf.pt</a>)</p>
              <p className="text"><b>Serviço</b>: CUF Descobertas</p>
              <p className="text"><b>Projeto</b>: agrupar casos de <b>intervilite crónica</b>, com possibilidade de acesso ao historial clínico, para alargar série de casos já apresentada no congresso da ESP. ênfase epidemiológica e na associação com doenças auto-imunes. Uma colaboração entre CUF, HSM e HGO, a procura de mais serviços.</p>
            </FlexInRow>
          </FlexInRow>
        </Container>
      </SectionProjects>      
    */}
    </Wrapper>
  );
}
