import styled from "styled-components";
import { useState } from "react";
import { Navigate, Link, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import authService from "../../../Services/auth.service";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    height: 100px;
    width: 100px;
    margin-right: 50px;
  }

  a {
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  a:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .perfil__info {
    width: calc(100% - 16.354vw - 3.125vw);
  }

  .sidebar__cta {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    border: 0.156vw solid #0c1151;
    padding: 0.781vw 1.563vw;
  }

  .sidebar__cta:hover {
    background-color: #0c1151;
    border-color: transparent;
    color: #e5e5e5;
  }

  .sidebar__cta.blue {
    background: #0c1151 0% 0% no-repeat padding-box;
    border: 0.156vw solid transparent;
    color: #ffffff;
  }

  .sidebar__cta.blue:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .sidebar__text {
    text-align: center;
    font-size: 1.25vw;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .perfil__text {
    text-align: left;
    font-size: 1.25vw;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: block;
    gap: 2.5px;

    a {
      display: initial;
    }
  }

  .perfil__resume {
    padding-bottom: 3.125vw;
  }

  .perfil__resume-title {
    text-align: left;
    font-size: 1.25vw;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }

  .perfil__resume .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .perfil__resume-text {
    text-align: left;
    font-size: 1.25vw;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  .perfil__resume-info {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    margin: 0;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .perfil__resume-text {
    }

    .perfil__resume-info {
      font-size: 2.222vw;
      line-height: 3.333vw;
    }
  }

  @media (min-width: 1920px) {
    .perfil__resume-info {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export default function PerfilManuel(props) {
  const { id } = useParams();

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [user, setUser] = useState(() => {
    let userData = [];
    session_storage?.all_data.users.filter((user) => {
      if (user.id == id) {
        userData = user;
      }
    });
    return userData;
  });

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title={"MANUEL SOBRINHO SIMÕES"}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Lista de Sócios",
              link: "/socios/lista-de-socios",
            },
            {
              text: "Manuel Sobrinho Simões",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-conheca-patologista-manuel.png")}
          imageMobile={require("../../../Assets/Images/img-conheca-patologista-manuel.png")}
          imageAlt="Manuel Sobrinho Simões"
          border={true}
        />

        <FlexInRow gap="4.010vw">
          <FlexInColumn gap="3.125vw">
            <FlexInRow gap="3.438vw" className="perfil__info">
              <div>
                <p className="perfil__text">
                  <b>Instituição: </b>Faculdade de Medicina da Universidade do
                  Porto/Centro Hospitalar S. João e Instituto de Patologia e
                  Imunologia Molecular da Universidade do Porto (IPATIMUP)
                </p>
                <p className="perfil__text">
                  <b>Grau: </b>Agregação/Prof. Catedrático e Chefe de Serviço
                </p>
                <p className="perfil__text">
                  <b>Área de Diferenciação: </b>Diagnóstico e investigação em
                  patologia endócrina com enfase na tireoide
                </p>
                <p className="perfil__text">
                  <b>Email: </b>
                  <a href="mailto:ssimoes@ipatimup.pt">ssimoes@ipatimup.pt</a>
                </p>
              </div>
              <div>
                <p className="perfil__text">
                  <b>ORCID: </b>0000-0003-1613-1235
                </p>
              </div>
            </FlexInRow>
            <FlexInRow className="sidebar">
              {/*}
              <a href="mailto:ssimoes@ipatimup.pt" className="sidebar__cta blue">
                Enviar mensagem
              </a>
              { user.segunda_opiniao > 0 && 
                <a href="#" className="sidebar__cta blue">
                  Pedir uma 2ª opinião
                </a>
              }
              <a href="#" className="sidebar__cta">
                Reportar perfil
              </a>
            {*/}
              <h4 className="sidebar__text">
                Sócio: {user.numero_socio ? user.numero_socio : "N/A"}
              </h4>
            </FlexInRow>
          </FlexInColumn>

          <FlexInRow gap="3.385vw" className="perfil__resume">
            <FlexInRow gap="1.042vw">
              <FlexInColumn gap="1.042vw">
                <h2 className="perfil__resume-title">Resumo Profissional</h2>
                <div className="bar"></div>
              </FlexInColumn>
              <p className="perfil__resume-text">
                Formação em medicina e especialização em anatomia patológica com
                doutoramento e agregação na Faculdade de Medicina da
                Universidade do Porto/Hospital de S. João. Pós-doutoramento e
                especialização em patologia oncológica e microscopia electrónica
                no Radium Hospital de Oslo. Fundador do Ipatimup em 1989
                e  Co-fundador do Instituto de Investigação e Inovação em Saúde
                (i3S) em 2008.
              </p>
              <p className="perfil__resume-text">
                Publicou cerca de 450 artigos científicos que deram origem a
                mais de 16000 citações (factor h{">"}65).   Foi-lhe atribuído o
                Honorary Fellowship do Royal College of Pathologists em 2018 e a
                Distinção Pio del Rio Hortega da Sociedade Espanhola de Anatomia
                Patológica em 2019. Actualmente é professor emérito da
                Universidade do Porto e director do Ipatimup.  Trabalhou sempre
                em regime de dedicação exclusiva.
              </p>
              <p className="perfil__resume-text">
                Presidiu à Sociedade Europeia de Patologia de 1999 a 2001, tendo
                criado Centros da Escola Europeia de Patologia em vários países.
                Ajudou a criar o EUROCELLPATH (European Courses on Cellular
                Pathology) e a Escola Europeia de Patologia (EScoP). Foi votado
                pelos seus pares, em 2015, o{" "}
                <i>“Patologista mais influente do mundo”</i> pela revista
                britânica <i>“The Pathologist”</i>. Foi fundador e
                Vice-presidente do Health Cluster Portugal de 2008 até 2017.
              </p>
              <p className="perfil__resume-text">
                Presidiu ao Conselho da Escola Nacional de Saúde Pública da
                Universidade Nova de Lisboa de 2009 a 2014. Presidiu ao Conselho
                Nacional dos Centros Académicos Clínicos de 2015 a 2021.
                Presidiu ao Conselho de Curadores da A3ES de 2017 a 2021.  
              </p>
            </FlexInRow>
            <div>
              <p className="perfil__resume-info">
                Os sócios da SPAP são responsáveis pela veracidade dos seus
                próprios perfis.
              </p>
              <p className="perfil__resume-info">
                Conheça a{" "}
                <Link to="/politica-de-privacidade">
                  política de privacidade
                </Link>{" "}
                deste website bem como os{" "}
                <Link to="/termos-e-condicoes">termos e condições</Link> do
                mesmo.
              </p>
            </div>
          </FlexInRow>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "Lista de sócios",
            link: "/socios/lista-de-socios",
          },
          {
            title: "Pedir uma 2ª opinião",
            link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
          },
          {
            title: "Área pessoal",
            link: "/socios/area-de-socio",
          },
          {
            title: "Contactos",
            link: "/spap/contactos",
          },
        ]}
      />
    </Wrapper>
  );
}
