import styled from "styled-components";
import { Navigate, Link, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import authService from "../../../Services/auth.service";
import React, { useEffect, useState } from "react";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    height: 100px;
    width: 100px;
    margin-right: 50px;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.6 !important;
  }

  a {
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  a:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .perfil__info {
    width: calc(100% - 16.354vw - 3.125vw);
  }

  .sidebar__cta {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    border: 0.156vw solid #0c1151;
    padding: 0.781vw 1.563vw;
  }

  .sidebar__cta:hover {
    background-color: #0c1151;
    border-color: transparent;
    color: #e5e5e5;
  }

  .sidebar__cta.blue {
    background: #0c1151 0% 0% no-repeat padding-box;
    border: 0.156vw solid transparent;
    color: #ffffff;
  }

  .sidebar__cta.blue:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .sidebar__text {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .perfil__text {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    gap: 2.5px;
  }

  .perfil__resume {
    padding-bottom: 3.125vw;
  }

  .perfil__resume-title {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    display: flex;
    align-users: center;
    justify-content: flex-start;
    margin: 0;
  }

  .perfil__resume .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .perfil__resume-text {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  .perfil__resume-info {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    margin: 0;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .perfil__resume-text {
    }

    .perfil__resume-info {
      font-size: 2.222vw;
      line-height: 3.333vw;
    }
  }

  @media (min-width: 1920px) {
    .perfil__resume-info {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export default function Perfil(props) {
  const { id } = useParams();

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [user, setUser] = useState([]);
  const [runFetch, setRunFetch] = useState(false);

  useEffect(() => {
    fetch(`https://api.spap.pt/api/get-user/` + id.split("-").pop(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar users");
        }
        return response.json();
      })
      .then((data) => {
        if (data.user.length === 0) {
          return;
        }
        setUser(data.user);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setRunFetch(true);
      });
  }, []);

  const setCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");

    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you

      // Assign it back to the array

      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    // Directly return the joined string

    return splitStr.join(" ");
  };

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title={user.name ? user.name + " " + user.apelido : ""}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
            },
          ]}
          imageDesktop={
            user.avatar
              ? "https://api.spap.pt/avatars/" + user.avatar
              : require("../../../Assets/Images/foto-default@2x.png")
          }
          imageMobile={
            user.avatar
              ? "https://api.spap.pt/avatars/" + user.avatar
              : require("../../../Assets/Images/foto-default@2x.png")
          }
          imageAlt=""
          border={true}
        />

        {runFetch && (
          <FlexInRow gap="4.010vw">
            <FlexInColumn gap="3.125vw">
              <FlexInRow gap="3.438vw" className="perfil__info">
                {(user.mostra_apresentacao_socios == 1 &&
                  user.tipo_utilizador == "socio_aprovado") ||
                user.mostra_apresentacao_publico == 1 ? (
                  <>
                    {" "}
                    <div>
                      <p className="perfil__text">
                        <b>Instituições: </b>
                        {user.instituicao && (
                          <span>{setCapitalize(user.instituicao)}, </span>
                        )}
                        {user.instituicao_2 && (
                          <span>{setCapitalize(user.instituicao_2)} </span>
                        )}
                      </p>

                      <p className="perfil__text">
                        <b>Grau: </b>

                        {user.grau && user.grau}
                        {user.grau_2 && user.grau
                          ? ", " + user.grau_2
                          : user.grau_2}
                        {!user.grau && !user.grau_2 && "N/A"}
                      </p>

                      <p className="perfil__text">
                        <b>Área(s) de Diferenciação:</b>

                        {user.area_subespecializacao &&
                          user.area_subespecializacao}
                        {user.area_subespecializacao_2 &&
                        user.area_subespecializacao
                          ? ", " + user.area_subespecializacao_2
                          : user.area_subespecializacao_2}
                        {user.area_diferenciacao_3 &&
                        (user.area_subespecializacao ||
                          user.area_subespecializacao_2)
                          ? ", " + user.area_diferenciacao_3
                          : user.area_diferenciacao_3}
                        {!user.area_subespecializacao &&
                          !user.area_subespecializacao_2 &&
                          !user.area_diferenciacao_3 &&
                          "N/A"}
                      </p>
                      {user.mostra_dados_socios == 1 && (
                        <p className="perfil__text">
                          <b>Email: </b>
                          {user.email}
                        </p>
                      )}
                      {user.mostra_dados_publico == 1 && (
                        <p className="perfil__text">
                          <b>Telefone: </b>
                          {user.telefone}
                        </p>
                      )}
                    </div>{" "}
                  </>
                ) : (
                  <></>
                )}
                {(user.mostra_dados_profissionais_socios == 1 &&
                  user.tipo_utilizador == "socio_aprovado") ||
                user.mostra_dados_profissionais_publico == 1 ? (
                  <>
                    {" "}
                    <div>
                      <p className="perfil__text">
                        <b>Sócio titular proponente: </b>
                        {user.socio_titular ? user.socio_titular : "N/A"}
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {(user.mostra_links_socio == 1 &&
                  user.tipo_utilizador == "socio_aprovado") ||
                user.mostra_links_publico == 1 ? (
                  <>
                    <div>
                      <p className="perfil__text">
                        <b>Página web: </b>
                        <a
                          href={user.pagina_web_1 ? user.pagina_web_1 : "#"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {user.pagina_web_1 ? user.pagina_web_1 : "N/A"}
                        </a>
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </FlexInRow>
              <FlexInRow className="sidebar">
                {/*}
              <a href="#" className="sidebar__cta blue disabled">
                Enviar mensagem
              </a>
              { user.segunda_opiniao > 0 && 
                <a href="#" className="sidebar__cta blue disabled">
                  Pedir uma 2ª opinião
                </a>
              }
              <a href="#" className="sidebar__cta disabled">
                Reportar perfil
              </a>
            {*/}
                <h4 className="sidebar__text">
                  Sócio: {user.numero_socio ? user.numero_socio : "N/A"}
                </h4>
              </FlexInRow>
            </FlexInColumn>

            <FlexInRow gap="3.385vw" className="perfil__resume">
              <FlexInRow gap="1.042vw">
                <FlexInColumn gap="1.042vw">
                  <h2 className="perfil__resume-title">Resumo Profissional</h2>
                  <div className="bar"></div>
                </FlexInColumn>
                {(user.mostra_resumo_socios == 1 &&
                  user.tipo_utilizador == "socio_aprovado") ||
                user.mostra_resumo_publico == 1 ? (
                  <>
                    <p className="perfil__resume-text">
                      {user.resumo_profissional
                        ? user.resumo_profissional
                        : "N/A"}{" "}
                    </p>
                    <p className="perfil__resume-text">
                      <b>ORCID: </b>
                      {user.orcid ? user.orcid : "N/A"}{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="perfil__resume-text">
                      Resumo Profissional não partilhado pelo sócio.
                    </p>
                  </>
                )}
              </FlexInRow>
              <div>
                <p className="perfil__resume-info">
                  Os sócios da SPAP são responsáveis pela veracidade dos seus
                  próprios perfis.
                </p>
                <p className="perfil__resume-info">
                  Conheça a{" "}
                  <Link to="/politica-de-privacidade">
                    política de privacidade
                  </Link>{" "}
                  deste website bem como os{" "}
                  <Link to="/termos-e-condicoes">termos e condições</Link> do
                  mesmo.
                </p>
              </div>
            </FlexInRow>
          </FlexInRow>
        )}
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "Lista de sócios",
            link: "/socios/lista-de-socios",
          },
          {
            title: "Pedir uma 2ª opinião",
            link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
          },
          {
            title: "Área pessoal",
            link: "/socios/area-de-socio",
          },
          {
            title: "Contactos",
            link: "/spap/contactos",
          },
        ]}
      />
    </Wrapper>
  );
}
