import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import RecommendedLinks from "../../../../Components/RecommendedLinks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.main`
  background-color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const Grid = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`;

const Text = styled.p`
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: block;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  a {
    color: #f88436;
    text-decoration: none;
    transition: 0.3s ease-out;
  }

  a:hover {
    text-decoration: underline;
  }

  &.n-bold {
    font-weight: normal;
  }

  @media (max-width: 992px) {
    font-size: 3.222vw;
    line-height: 4.333vw;
  }
`;

const Picture = styled.picture`
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};

  @media (max-width: 992px) {
    max-width: 150px;
  }
`;

export default function FormacaoOnline(props) {
  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title="Formação Online"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Formação",
              link: "/ferramentas-uteis/formacao",
            },
            {
              text: "Formação Online",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/img-top-agenda@2x.png")}
          imageMobile={require("../../../../Assets/Images/img-top-agenda.png")}
        />

        <FlexInRow gap="3.542vw">
          <Grid>
            <FlexInColumn gap="20px">
              <a
                href="https://pathologycast.com/index.php/pathCast"
                target="_blank"
              >
                <Picture width={"80%"}>
                  <source
                    srcSet={require("../../../../Assets/Images/Path-Cast@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../../Assets/Images/Path-Cast.png")}
                    alt={"Pathcast"}
                  />
                </Picture>
              </a>
              <a href="https://webpath.med.utah.edu/" target="_blank">
                <Picture width={"80%"}>
                  <source
                    srcSet={require("../../../../Assets/Images/Web-Path@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../../Assets/Images/Web-Path.png")}
                    alt={"Webpath"}
                  />
                </Picture>
              </a>
              <a href="https://www.gupathsociety.org/" target="_blank">
                <Picture width={"80%"}>
                  <source
                    srcSet={require("../../../../Assets/Images/Gups.jpg")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../../Assets/Images/Gups.jpg")}
                    alt={"GUPS"}
                  />
                </Picture>
              </a>
            </FlexInColumn>
            <FlexInRow>
              <Text className="n-bold">
                Não encontra o que procura? Conhece outros? <br />
                Contacte-nos:{" "}
                <a className="link" href="mailto:site@spap.phormuladev.com">
                  site@spap.phormuladev.com
                </a>
              </Text>
            </FlexInRow>
          </Grid>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
