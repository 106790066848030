import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import RecommendedLinks from "../../../../Components/RecommendedLinks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.main`
  background-color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  a.orange {
    color: ${(props) => props.theme.orange};
    text-decoration: underline;
    transition: 0.3s ease-out;
  }

  a.orange:hover {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const Grid = styled.section`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5.4166vw;
  justify-content: center;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const Text = styled.p`
  text-align: left;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: block;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  a {
    color: #f88436;
    text-decoration: none;
  }

  @media (max-width: 992px) {
    font-size: 2.222vw;
    line-height: 3.333vw;
  }
`;

const Card = styled.div`
  border: 3px solid #0c1151;
  border-radius: 0px 0px 20px 20px;

  .card__title {
    background-color: #0c1151;
    color: #fff;
    text-align: center;
    padding: 10px;
  }

  .card__content {
    padding: 30px 10px;

    a {
      font-family: "Fira Sans", sans-serif;
      letter-spacing: 0px;
      color: #0c1151;
      text-decoration: none;
      margin-bottom: 20px;
      transition: 0.3s ease;
      text-align: left;
      display: flex;
      font-size: 16px;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 992px) {
    .card__content {
      padding: 20px 10px;

      a {
        font-size: 2.222vw;
      }
    }
  }
`;

export default function FormacaoLinksUteis(props) {
  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title="Links Úteis"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Formação",
              link: "/ferramentas-uteis/formacao",
            },
            {
              text: "Links Úteis",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/img-top-agenda@2x.png")}
          imageMobile={require("../../../../Assets/Images/img-top-agenda.png")}
        />

        <FlexInRow gap="3.542vw">
          <Grid>
            <Card>
              <Text className="card__title">Outras Sociedades</Text>
              <div className="card__content">
                <a href="https://spcitologia.pt/" target="_blank">
                  Sociedade Portuguesa de Citologia (SPC)
                </a>
                <a href="https://www.esp-pathology.org/" target="_blank">
                  European Society of Pathology (ESP)
                </a>
                <a href="https://uscap.org/" target="_blank">
                  The United States and Canadian Academy of Pathology (USCAP)
                </a>
                <a href="https://www.cap.org/#!" target="_blank">
                  College of American Pathologists (CAP)
                </a>
                <a href="https://www.esmo.org/" target="_blank">
                  European Society for Medical Oncology (ESMO)
                </a>
                <a href="https://www.iapcentral.org/" target="_blank">
                  International Academy of Pathology (IAP)
                </a>
                <a href="https://www.efcs.eu/" target="_blank">
                  European Federation of Cytology Societies (EFCS)
                </a>
                <a href="https://www.rcpath.org/" target="_blank">
                  The Royal College of Pathologists
                </a>
                <a href="https://www.eacr.org/" target="_blank">
                  European Association for Cancer Research (EACR)
                </a>
              </div>
            </Card>
            <Card>
              <Text className="card__title">Revistas Cientifícas e Afins</Text>
              <div className="card__content">
                <a href="https://pubmed.ncbi.nlm.nih.gov/" target="_blank">
                  Pubmed
                </a>
                <a href="https://www.springer.com/journal/428" target="_blank">
                  Virchows Archiv
                </a>
                <a
                  href="https://journals.lww.com/ajsp/pages/default.aspx"
                  target="_blank"
                >
                  The American Journal of Surgical Pathology
                </a>
                <a href="https://meridian.allenpress.com/aplm" target="_blank">
                  Archives of Pathology & Laboratory Medicine
                </a>
                <a
                  href="https://onlinelibrary.wiley.com/journal/13652559"
                  target="_blank"
                >
                  Histopathology
                </a>
                <a
                  href="https://onlinelibrary.wiley.com/journal/13652303"
                  target="_blank"
                >
                  Cytopathology
                </a>
                <a
                  href="https://journals.lww.com/appliedimmunohist/pages/default.aspx"
                  target="_blank"
                >
                  Applied Immunohistochemistry & Molecular Morphology
                </a>
                <a
                  href="https://www.karger.com/journal/Home/254338"
                  target="_blank"
                >
                  Acta cytologica
                </a>
                <a href="https://www.pathologyoutlines.com/" target="_blank">
                  Pathology Outlines
                </a>
              </div>
            </Card>
          </Grid>
          <p>
            Não encontra o que procura? Conhece outros? <br />
            Contacte-nos:{" "}
            <a
              href="mailto:site@spap.phormuladev.com"
              target="_blank"
              className="orange"
            >
              site@spap.phormuladev.com
            </a>
          </p>
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
