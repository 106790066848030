import styled from "styled-components";
import Notification from "./Notification";
import Menu from "./Menu";
import { BackofficeStyle } from "../../../Config";
import authService from "../../../Services/auth.service";
import { ReactComponent as LogoSpap } from "../../../Assets/Images/Backoffice/Main-Menu/SPAP.svg";

const Wrapper = styled.section`
  position: relative;
  padding-bottom: 90px;

  ${(props) =>
    props.notAdmin &&
    `
    header {
      background-color: rgb(73, 77, 125);
    }  

    .profile__text {
      color: #fff;
    }
  `}

  @media (max-width: 1280px) {
    padding-bottom: 130px;
  }

  @media (max-width: 992px) {
  }
`;

const TopMenu = styled.header`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  padding: 1.771vw;
  width: 95% !important;
  position: fixed;
  right: 0 !important;
  top: 0;
  width: calc(100% - 7.084vw);
  z-index: 88;

  .ant-badge-count {
    background-color: #d8722d;
    top: 100%;
  }

  @media (max-width: 992px) {
    width: 100%;
    grid-template-columns: 1fr;
    padding: 20px 0;
  }
`;

export default function Header(props) {
  let data = authService.isAuthenticated();

  return (
    <>
      <BackofficeStyle />
      <Wrapper
        className={props.className}
        notAdmin={!(data.all_data.role == "admin") && true}
      >
        <TopMenu>
          <Notification />
          <Menu />
        </TopMenu>
      </Wrapper>
    </>
  );
}
