import React from "react";
import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { styledTheme } from "../Config";
import { Button, Modal, Result, Space } from "antd";

import Home from "../Pages/Home";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PoliticaPrivacidade from "../Pages/PoliticaPrivacidade";
import TermosCondicoes from "../Pages/TermosCondicoes";

import Spap from "../Pages/Spap";
import Historia from "../Pages/Spap/Historia";
import Presidentes from "../Pages/Spap/Historia/Presidentes";
import Congressos from "../Pages/Spap/Historia/Congressos";
import BolsasPremios from "../Pages/Spap/BolsasPremios";
import TodasNoticias from "../Pages/Spap/Noticia/VerTodas";
import Noticia from "../Pages/Spap/Noticia";
import NoticiaPremios from "../Pages/Spap/Noticia/Premios";
import Contactos from "../Pages/Spap/Contactos";

import Socios from "../Pages/Socios";
import Perfil from "../Pages/Socios/Perfil";
import DadosDePerfil from "../Pages/Socios/DadosDePerfil";
import ListaSocios from "../Pages/Socios/ListaSocios";
import ConhecaVantagens from "../Pages/Socios/ConhecaVantagens";
import Destaques from "../Pages/Socios/Destaques";
import ClubesEstudo from "../Pages/Socios/ClubesEstudo";
import Clube from "../Pages/Socios/Clube";
import PerguntasFrequentes from "../Pages/Socios/PerguntasFrequentes";

import FerramentasUteis from "../Pages/FerramentasUteis";
import PesquisarAnticorpo from "../Pages/FerramentasUteis/PesquisarAnticorpo";
import Guidelines from "../Pages/FerramentasUteis/Guidelines";
import CasoDoMes from "../Pages/FerramentasUteis/CasoDoMes";
import Formacao from "../Pages/FerramentasUteis/Formacao";
import FormacaoCursosCongressos from "../Pages/FerramentasUteis/Formacao/CursosCongressos";
import FormacaoOnline from "../Pages/FerramentasUteis/Formacao/FormacaoOnline";
import FormacaoLinksUteis from "../Pages/FerramentasUteis/Formacao/LinkaUteis";
import FormacaoPost from "../Pages/FerramentasUteis/Formacao/Post";
import Revista from "../Pages/FerramentasUteis/Revista";
import ParceriasProjetos from "../Pages/FerramentasUteis/ParceriasProjetos";

import Agenda from "../Pages/Agenda";
import Evento from "../Pages/Agenda/Evento";

import Nispap from "../Pages/NISPAP";
import QuemSomos from "../Pages/NISPAP/SobreNos/QuemSomos";
import Iniciativas from "../Pages/NISPAP/SobreNos/Iniciativas";
import Eventos from "../Pages/NISPAP/Novidades/Eventos";
import SinglePageEvento from "../Pages/NISPAP/Novidades/Eventos/SinglePage";
import GaleriaDeFotos from "../Pages/NISPAP/Novidades/GaleriaDeFotos";
import Estagios from "../Pages/NISPAP/Utilitarios/Estagios";
import ReunioesInterservicos from "../Pages/NISPAP/Utilitarios/ReunioesInterservicos";
import Reuniao from "../Pages/NISPAP/Utilitarios/ReunioesInterservicos/SinglePage";
import Publicacoes from "../Pages/NISPAP/Utilitarios/Publicacoes";
import LinksUteis from "../Pages/NISPAP/Utilitarios/LinksUteis";
import NispapContactos from "../Pages/NISPAP/Contactos";

import Backoffice from "../Pages/Backoffice";
import Dashboard from "../Pages/Backoffice/Dashboard";
import DashboardQuotas from "../Pages/Backoffice/Quotas";
import DashboardCertificados from "../Pages/Backoffice/Certificados";
import DashboardClubes from "../Pages/Backoffice/Clubes";
import DashboardAnticorpos from "../Pages/Backoffice/Anticorpos";
import DashboardSocios from "../Pages/Backoffice/Socios";
import DashboardPatologistas from "../Pages/Backoffice/Patologistas";
import DashboardCasoDoMes from "../Pages/Backoffice/CasoDoMes";
import DashboardPerfil from "../Pages/Backoffice/Perfil";
import DashboardMensagens from "../Pages/Backoffice/Mensagens";

import Blank from "../Pages/Blank";
import authService from "../Services/auth.service";
import { Nav } from "react-bootstrap";
import SociosMensagens from "../Pages/Socios/Mensagens";
import RecuperarSenha from "../Pages/Backoffice/RecuperarSenha";
import Documentos from "../Pages/Socios/Documentos";
import DashWarnings from "../Pages/Backoffice/Warnings";
import ClubeNovo from "../Pages/Socios/ClubeNovo";
import TodosClubes from "../Pages/Backoffice/TodosClubes";
import ClubeNovoDocumentos from "../Pages/Socios/ClubeNovo/Documentos";
import ClubeNovoMembros from "../Pages/Socios/ClubeNovo/Membros";
import ClubeNovoEventos from "../Pages/Socios/ClubeNovo/Eventos";
import ClubeNovoGaleria from "../Pages/Socios/ClubeNovo/Galeria";
import PerfilManuel from "../Pages/Socios/PerfilManuel";
import Pagamentos from "../Pages/Socios/Pagamentos";
import DashboardEventos from "../Pages/Backoffice/Eventos";
import DashboardApresentacoes from "../Pages/Backoffice/Apresentacoes";
import DashboardEmails from "../Pages/Backoffice/Emails";
import DashboardFicheiros from "../Pages/Backoffice/Ficheiros";
import DashboardBanner from "../Pages/Backoffice/Banner";
import DashboardLogs from "../Pages/Backoffice/Logs";
import DashboardNewsletters from "../Pages/Backoffice/Newsletters";
import SociosQuotas from "../Pages/Socios/Quotas";
import SociosEventos from "../Pages/Socios/Eventos";
import DashboardEstatutosDocumentos from "../Pages/Backoffice/EstatutosDocumentos";
import TodasBolsaPremios from "../Pages/Spap/BolsasPremios/VerTodas";
import DashboardRevista from "../Pages/Backoffice/Revista";
import SpapApresentacoes from "../Pages/Spap/Apresentacoes";
import TodasApresentacoes from "../Pages/Spap/Apresentacoes/VerTodas";
import Apresentacao from "../Pages/Spap/Apresentacoes/SinglePage";

import { ReactComponent as IconNoLogin } from "../Assets/Images/No-login.svg";
import { ReactComponent as IconWaithingAproval } from "../Assets/Images/Waithing-Aproval.svg";
import Loading from "../Components/Backoffice/Loading";
import CasoDoMesPage from "../Pages/FerramentasUteis/CasoDoMes/SinglePage";
import InformacoesPagamentos from "../Pages/InformacoesPagamentos";

const ModeratorRoute = ({ children }) => {
  let data = authService.isAuthenticated();

  if (data.all_data.role == "socio_aprovado") {
    return children;
  } else {
    return <PrivateRoute>{children}</PrivateRoute>;
  }
};

const PrivateRoute = ({ children }) => {
  let data = authService.isAuthenticated();
  if (data == null) {
    return <Navigate to="/login" />;
  }

  if (data.all_data.role == "admin") {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const LogoutRoute = ({ children }) => {
  let data = authService.logout();
  return <Navigate to="/" />;
};

const AuthRoute = ({ children }) => {
  let data = authService.isAuthenticated();
  if (data == null) {
    return <Navigate to="/login" />;
  }

  // if(data.all_data.role == 'admin') {
  //   return <Navigate to="/dashboard/perfil"/>;
  // } else {
  //   return children;
  // }
};

function perfilRoute(children) {
  let data = authService.isAuthenticated();

  if (data == null) {
    return <Navigate to="/" />;
  }

  // if(data?.all_data.role == 'admin' ) {
  //   return <Navigate to="/dashboard/perfil"/>;
  // }

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default function () {
  return (
    <ThemeProvider theme={styledTheme(useLocation())}>
      <Routes>
        <Route path="/spap_react" element={<Navigate to="/" />} />
        <Route
          index
          path="/"
          caseSensitive={false}
          element={frontOffice(<Home />)}
        />
        <Route
          index
          path="/politica-de-privacidade"
          caseSensitive={false}
          element={frontOffice(<PoliticaPrivacidade />)}
        />
        <Route
          index
          path="/termos-e-condicoes"
          caseSensitive={false}
          element={frontOffice(<TermosCondicoes />)}
        />
        <Route
          index
          path="/informacoes-pagamentos"
          caseSensitive={false}
          element={frontOffice(<InformacoesPagamentos />)}
        />

        <Route path="/spap" element={<Navigate to="/spap/quem-somos" />} />
        <Route
          path="/spap/historia"
          caseSensitive={false}
          element={frontOffice(<Historia />)}
        />
        <Route
          path="/spap/presidentes"
          caseSensitive={false}
          element={frontOffice(<Presidentes />)}
        />
        <Route
          path="/spap/congressos"
          caseSensitive={false}
          element={frontOffice(<Congressos />)}
        />

        <Route
          path="/spap/bolsas-e-premios"
          caseSensitive={false}
          element={frontOffice(<BolsasPremios />)}
        />
        {/*
          Caso queira direcionar para pagina da noticia
          <Route path="/spap/bolsas-e-premios" element={<Navigate to="/spap/bolsas-e-premios/spap-bolsa-2023-2a-edicao-67" />} />
          */}
        <Route
          path="/spap/bolsas-e-premios/ver-todas"
          caseSensitive={false}
          element={frontOffice(<TodasBolsaPremios />)}
        />
        <Route
          path="/spap/bolsas-e-premios/:slug"
          caseSensitive={false}
          element={frontOffice(<Evento isBolsa={true} />)}
        />
        <Route
          path="/spap/noticias"
          caseSensitive={false}
          element={frontOffice(
            <TodasNoticias title="Todas Bolsas e Prémios" />
          )}
        />
        <Route
          path="/spap/noticias/ver-todas"
          caseSensitive={false}
          element={frontOffice(
            <TodasNoticias title="Todas Bolsas e Prémios" />
          )}
        />
        <Route
          path="/spap/noticia/premios"
          caseSensitive={false}
          element={frontOffice(<NoticiaPremios />)}
        />
        <Route
          path="/spap/noticia/:slug"
          caseSensitive={false}
          element={frontOffice(<Noticia />)}
        />
        <Route
          path="/spap/contactos"
          caseSensitive={false}
          element={frontOffice(<Contactos />)}
        />
        <Route
          path="/spap/:slug"
          caseSensitive={false}
          element={frontOffice(<Spap />)}
        />

        <Route
          path="/socios"
          element={<Navigate to="/socios/area-de-socio" />}
        />
        <Route
          path="/socios/area-de-socio"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<Socios />)}
        />
        <Route
          path="/socios/dados-de-perfil"
          caseSensitive={false}
          element={perfilRoute(<DadosDePerfil />)}
        />
        <Route
          path="/socios/mensagens"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<SociosMensagens />)}
        />
        <Route
          path="/socios/quotas"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<SociosQuotas />)}
        />
        <Route
          path="/socios/meus-eventos"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<SociosEventos />)}
        />
        <Route
          path="/socios/pagamentos"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<Pagamentos />)}
        />
        <Route
          path="/socios/perfil/manuel-sobrinho-simoes"
          caseSensitive={false}
          element={frontOffice(<PerfilManuel />)}
        />
        <Route
          path="/socios/perfil/:id"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<Perfil />)}
        />
        <Route
          path="/socios/lista-de-socios"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<ListaSocios />)}
        />
        <Route
          path="/socios/conheca-as-vantagens"
          caseSensitive={false}
          element={frontOffice(<ConhecaVantagens />)}
        />
        <Route
          path="/socios/destaques"
          caseSensitive={false}
          element={frontOffice(<Destaques />)}
        />
        <Route
          path="/socios/destaques/ver-todos"
          caseSensitive={false}
          element={frontOffice(<TodasNoticias />)}
        />
        <Route
          path="/socios/clubes-de-estudo"
          caseSensitive={false}
          element={frontOffice(<ClubesEstudo />)}
        />
        <Route
          path="/socios/clubes-de-estudo/:slug"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<ClubeNovo />)}
        />
        <Route
          path="/socios/clubes-de-estudo/:slug/membros"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<ClubeNovoMembros />)}
        />
        <Route
          path="/socios/clubes-de-estudo/:slug/eventos"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<ClubeNovoEventos />)}
        />
        <Route
          path="/socios/clubes-de-estudo/:slug/galeria"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<ClubeNovoGaleria />)}
        />
        <Route
          path="/socios/clubes-de-estudo/:slug/documentos"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<ClubeNovoDocumentos />)}
        />
        {/* <Route path="/socios/clubes-de-estudo/:slug" caseSensitive={false} element={frontOfficeLoggedIn(<Clube />)} /> */}
        <Route
          path="/socios/perguntas-frequentes"
          caseSensitive={false}
          element={frontOffice(<PerguntasFrequentes />)}
        />

        <Route
          path="/ferramentas-uteis"
          element={
            <Navigate to="/ferramentas-uteis/pedir-uma-segunda-opiniao" />
          }
        />
        <Route
          path="/ferramentas-uteis/pedir-uma-segunda-opiniao"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<FerramentasUteis />)}
        />
        <Route
          path="/ferramentas-uteis/pesquisar-um-anticorpo"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<PesquisarAnticorpo />)}
        />
        <Route
          path="/ferramentas-uteis/guidelines"
          caseSensitive={false}
          element={frontOffice(<Guidelines />)}
        />
        <Route
          path="/ferramentas-uteis/caso-do-mes"
          caseSensitive={false}
          element={frontOffice(<CasoDoMes />)}
        />
        <Route
          path="/ferramentas-uteis/caso-do-mes/:slug"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<CasoDoMesPage />)}
        />
        <Route
          path="/ferramentas-uteis/formacao"
          caseSensitive={false}
          element={frontOffice(<Formacao />)}
        />
        <Route
          path="/ferramentas-uteis/formacao/cursos-congressos"
          caseSensitive={false}
          element={frontOffice(<SpapApresentacoes />)}
        />
        <Route
          path="/ferramentas-uteis/formacao/cursos-congressos/ver-todas"
          caseSensitive={false}
          element={frontOffice(<TodasApresentacoes />)}
        />
        <Route
          path="/ferramentas-uteis/formacao/cursos-congressos/:slug"
          caseSensitive={false}
          element={frontOffice(<Apresentacao />)}
        />
        <Route
          path="/ferramentas-uteis/formacao/formacao-online"
          caseSensitive={false}
          element={frontOffice(<FormacaoOnline />)}
        />
        <Route
          path="/ferramentas-uteis/formacao/links-uteis"
          caseSensitive={false}
          element={frontOffice(<FormacaoLinksUteis />)}
        />
        <Route
          path="/ferramentas-uteis/formacao/:slug"
          caseSensitive={false}
          element={frontOffice(<FormacaoPost />)}
        />

        <Route
          path="/ferramentas-uteis/publicacoes"
          caseSensitive={false}
          element={frontOffice(<Publicacoes />)}
        />
        <Route
          path="/ferramentas-uteis/revista"
          caseSensitive={false}
          element={frontOffice(<Revista />)}
        />
        <Route
          path="/ferramentas-uteis/parcerias"
          caseSensitive={false}
          element={frontOffice(<ParceriasProjetos />)}
        />

        <Route
          path="/agenda"
          caseSensitive={false}
          element={frontOffice(<Agenda />)}
        />
        <Route
          path="/agenda/evento/:slug"
          caseSensitive={false}
          element={frontOffice(<Evento />)}
        />

        <Route
          path="/nispap"
          caseSensitive={false}
          element={frontOffice(<Nispap />)}
        />
        <Route
          path="/nispap/sobre-nos/quem-somos"
          caseSensitive={false}
          element={frontOffice(<QuemSomos />)}
        />
        <Route
          path="/nispap/sobre-nos/iniciativas"
          caseSensitive={false}
          element={frontOffice(<Iniciativas />)}
        />
        <Route
          path="/nispap/novidades"
          element={<Navigate to="/nispap/novidades/eventos-nispap" />}
        />
        <Route
          path="/nispap/novidades/eventos-nispap"
          caseSensitive={false}
          element={frontOffice(<Eventos />)}
        />
        <Route
          path="/nispap/novidades/evento/2-encontro-nacional-de-internos-de-anatomia-patologica"
          caseSensitive={false}
          element={frontOffice(<SinglePageEvento second={true} />)}
        />
        <Route
          path="/nispap/novidades/evento/:slug"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<SinglePageEvento />)}
        />
        <Route
          path="/nispap/novidades/galeria-de-fotos"
          caseSensitive={false}
          element={frontOffice(<GaleriaDeFotos />)}
        />
        <Route
          path="/nispap/utilitarios"
          element={<Navigate to="/nispap/utilitarios/estagios" />}
        />
        <Route
          path="/nispap/utilitarios/estagios"
          caseSensitive={false}
          element={frontOffice(<Estagios />)}
        />
        <Route
          path="/nispap/utilitarios/reunioes-interservicos"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<ReunioesInterservicos />)}
        />
        <Route
          path="/nispap/utilitarios/reuniao/:slug"
          caseSensitive={false}
          element={frontOfficeLoggedIn(<Reuniao />)}
        />
        <Route
          path="/nispap/utilitarios/links-uteis"
          caseSensitive={false}
          element={frontOffice(<LinksUteis />)}
        />
        <Route
          path="/nispap/contactos"
          caseSensitive={false}
          element={frontOffice(<NispapContactos />)}
        />

        <Route
          path="/login"
          caseSensitive={false}
          element={LoginRoute(<Backoffice form={"login"} />)}
        />
        <Route
          path="/register"
          caseSensitive={false}
          element={LoginRoute(<Backoffice form={"register"} />)}
        />
        <Route
          path="/recuperar-senha"
          caseSensitive={false}
          element={<RecuperarSenha />}
        />
        <Route
          path="/logout"
          caseSensitive={false}
          element={<LogoutRoute></LogoutRoute>}
        />

        {/********************
         * AuthProvider
         *********************/}
        <Route
          path="/dashboard"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/quotas"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardQuotas />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/certificados"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardCertificados />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/clubes"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardClubes />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/todos-clubes"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <TodosClubes />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/clubes/:slug"
          caseSensitive={false}
          element={
            <ModeratorRoute>
              <DashboardClubes />
            </ModeratorRoute>
          }
        />
        <Route
          path="/dashboard/eventos"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardEventos />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/apresentacoes"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardApresentacoes />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/emails"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardEmails />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/banner"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardBanner />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/estatutos-e-documentos"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardEstatutosDocumentos />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/newsletters"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardNewsletters />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/revistas"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardRevista />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/logs"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardLogs />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/ficheiros"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardFicheiros />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/anticorpos"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardAnticorpos />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/socios"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardSocios />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/patologistas"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardPatologistas />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/caso-do-mes"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardCasoDoMes />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/perfil"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardPerfil />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/mensagens"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashboardMensagens />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/warnings"
          caseSensitive={false}
          element={
            <PrivateRoute>
              <DashWarnings />
            </PrivateRoute>
          }
        />

        {/* Blank route */}
        <Route path="*" exact={true} element={frontOffice(<Blank />)} />
      </Routes>
    </ThemeProvider>
  );
}

function LoginRoute(child) {
  let data = authService.isAuthenticated();

  if (data == null) {
    return child;
  }

  if (data.all_data.role == "admin") {
    return <Navigate to="/dashboard" />;
  } else {
    return <Navigate to="/" />;
  }
}

function frontOfficeLoggedIn(child) {
  let data = authService.isAuthenticated();

  if (data == null) {
    return (
      <>
        <Header />
        <Result
          style={{ marginTop: "180px", marginBottom: "100px" }}
          status="error"
          icon={<IconNoLogin />}
          extra={
            <Space direction="vertical" size={20}>
              <div className="ant-result-subtitle">
                Página de acesso utilizadores registados. Por favor,{" "}
                <Link to={"/login"} style={{ color: "#F88436" }}>
                  faça login
                </Link>
              </div>
              <Link to={"/login"} style={{ textDecoration: "none" }}>
                <Button
                  type="primary"
                  style={{
                    borderRadius: "15px",
                    height: "auto",
                    padding: "12px 30px",
                    display: "block",
                    margin: "0 auto",
                    background: "#F88436",
                    color: "#fff",
                  }}
                >
                  Ir para a página de Login
                </Button>
              </Link>
            </Space>
          }
        />

        <Footer />
        <Loading />
      </>
    );
  }

  if (data?.all_data.role == "nao_socio") {
    return (
      <>
        <Header />
        <Result
          style={{ marginTop: "180px", marginBottom: "100px" }}
          status="error"
          icon={<IconWaithingAproval />}
          extra={
            <Space direction="vertical" size={20}>
              <div className="ant-result-subtitle">
                Página de acesso restrita a sócios.
              </div>
              <Link to={"/login"} style={{ textDecoration: "none" }}>
                <Button
                  type="primary"
                  style={{
                    borderRadius: "15px",
                    height: "auto",
                    padding: "12px 30px",
                    display: "block",
                    margin: "0 auto",
                    background: "#F88436",
                    color: "#fff",
                  }}
                >
                  Ir para a página de Login
                </Button>
              </Link>
            </Space>
          }
        />
        <Footer />
        <Loading />
      </>
    );
  }

  if (
    data?.all_data.role == "socio_nao_aprovado_especialista" ||
    data?.all_data.role == "socio_nao_aprovado_interno"
  ) {
    return (
      <>
        <Header />
        <Result
          style={{ marginTop: "180px", marginBottom: "100px" }}
          status="error"
          icon={<IconWaithingAproval />}
          extra={
            <Space direction="vertical" size={20}>
              <div className="ant-result-subtitle">
                Página de acesso a sócios. Terá de esperar pela{" "}
                <b>aprovação do administrador.</b>
              </div>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <Button
                  type="primary"
                  style={{
                    borderRadius: "15px",
                    height: "auto",
                    padding: "12px 30px",
                    display: "block",
                    margin: "0 auto",
                    background: "#F88436",
                    color: "#fff",
                  }}
                >
                  Ir para a página principal
                </Button>
              </Link>
            </Space>
          }
        />
        <Footer />
        <Loading />
      </>
    );
  }

  if (data?.all_data.role != "nao_socio") {
    return (
      <>
        <Header />
        {child}
        <Footer />
        <Loading />
      </>
    );
  }
}

function frontOffice(child) {
  return (
    <>
      <Header />
      {child}
      <Footer />
      <Loading />
    </>
  );
}
