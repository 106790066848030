import styled from "styled-components";
import { Link } from "react-router-dom";
import { Space, Divider, Button } from "antd";

const Wrapper = styled.section`
  margin: 25px 0;

  @media (min-width: 992px) {
  }

  @media (max-width: 992px) {
  }
`;

const Grid = styled.div`
  align-items: flex-end;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
  }
`;

export default function JuntarseClube(props) {
  return (
    <Wrapper>
      <Divider orientation="left" orientationMargin="0">
        <Title>Junte-se ao clube!</Title>
      </Divider>
      <Grid>
        <picture>
          <source
            srcSet={require("../../Assets/Images/img-fetoplacentar-01@2x.png")}
            media="(min-width: 990px)"
          />
          <img
            src={require("../../Assets/Images/img-fetoplacentar-01.png")}
            alt="Jornadas Luso-Galaicas, Setembro de 1970, Coimbra"
          />
        </picture>
        <Button className="cta">Tornar-se membro</Button>
        <picture>
          <source
            srcSet={require("../../Assets/Images/img-fetoplacentar-02@2x.png")}
            media="(min-width: 990px)"
          />
          <img
            src={require("../../Assets/Images/img-fetoplacentar-02.png")}
            alt="Jornadas Luso-Galaicas, Setembro de 1970, Coimbra"
          />
        </picture>
        <Space direction="vertical">
          <Title>Contacte-nos!</Title>
          <span>
            Email:
            <a
              href="mailto:clubefetoplacentar@spap.phormuladev.com"
              className="link"
            >
              clubefetoplacentar@spap.phormuladev.com
            </a>
          </span>
        </Space>
      </Grid>
    </Wrapper>
  );
}
