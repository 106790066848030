import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import authService from "../../Services/auth.service";

import Container from "../Container";
import FlexInColumn from "../FlexInColumn";
import FlexInRow from "../FlexInRow";
import Recaptcha from "../Recaptcha";

import ScrollToTop from "react-scroll-to-top";
import { ReactComponent as SvgUp } from "../../Assets/Images/icon-up.svg";
import { Button, Modal, Result } from "antd";
import FooterCards from "./FooterCards";

const Wrapper = styled.footer`
  background-color: #0c1151;
  color: #ffffff;
  font-size: 0.833vw;
  letter-spacing: 0px;
  line-height: 1.563vw;
  text-align: left;
  width: 100%;

  .content {
    display: flex;
  }

  > .content {
    padding: 1.563vw 0;
  }

  .scroll-to-top {
    background-color: transparent;
    box-shadow: none;
    z-index: 999999;
    bottom: 120px;
  }

  .scroll-to-top svg {
    height: 3.021vw;
    width: 3.021vw;
    filter: drop-shadow(3px 6px 2px rgb(0 0 0 / 0.2));
  }

  .scroll-to-top svg path {
    transition: 0.3s ease;
  }

  .scroll-to-top svg path {
    fill: ${(props) => props.theme.orange};
  }

  .scroll-to-top svg:hover path {
    fill: #fde0cd;
  }

  @media (min-width: 992px) {
    .content {
      justify-content: space-between;
    }

    .grid {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 992px) {
    > .content {
      padding: 13.889vw 0;
      flex-direction: column;
      gap: 40px;
    }

    .scroll-to-top {
      bottom: 100px;
      right: 30px;
    }

    .scroll-to-top svg {
      height: 45px;
      width: 45px;
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin: 0 40px;
    }

    .grid.solo {
      grid-template-columns: 1fr;
    }

    picture {
      place-self: center;
    }

    picture img {
      width: 100% !important;
    }

    .img-parceiros img {
      width: 120px !important;
    }
  }

  @media (min-width: 1920px) {
    .grid {
      gap: 20px;

      img {
        width: 4.2187vw;
      }
    }

    .img-parceiros img {
      width: 6.2187vw;
    }

    .social-icon img {
      width: 50px;
    }

    .flex-links {
      gap: 60px;
    }

    .scroll-to-top,
    .scroll-to-top svg {
      height: 80px;
      width: 80px;
    }
  }
`;

const Title = styled.h3`
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  opacity: 1;
  text-transform: uppercase;

  @media (min-width: 992px) {
    text-align: left;
    ${(props) => props.right && ` text-align: right; `}
  }

  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }
`;

const HrInRow = styled.div`
  background-color: #ffffff;
  height: 1px;
  opacity: 0.5;
  width: 100%;
`;

const HrInColumn = styled.div`
  background-color: #ffffff;
  opacity: 0.5;
  margin: 0.7815vw 0;

  @media (min-width: 992px) {
    width: 1px;
  }

  @media (max-width: 992px) {
    height: 1px;
    width: 100%;
  }
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    transition: 0.3s ease;
    cursor: pointer;
    align-items: center;
    display: flex;
  }

  li a {
    color: #ffffff;
    text-decoration: none;
  }

  li:hover,
  li:hover a {
    color: #f88436;
  }

  @media (min-width: 992px) {
    li {
      justify-content: flex-start;
    }

    li a {
      text-align: left;
    }
  }

  @media (max-width: 992px) {
    li {
      justify-content: center;
    }

    li a {
      opacity: 0.75;
      font-size: 12px;
      line-height: 26px;
      text-align: center;
    }
  }
`;

const Newsletter = styled.form`
  position: relative;

  input[type="checkbox"] {
    margin: 0 10px 0 0;
  }

  > div {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  a {
    color: #f88436;
    transition: 0.3s ease;

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 992px) {
    input[type="checkbox"] {
      height: 21px;
      width: 21px;
    }

    span {
      font-size: 10px;
      line-height: 25px;
    }
  }
`;

const Logo = styled.img`
  width: 5.625vw;
  width: ${(props) => props.width} !important;

  @media (min-width: 992px) {
    width: 7.625vw;
  }

  @media (min-width: 1920px) {
    width: 8.2187vw;
  }
`;

const NewsletterEmail = styled.input`
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  color: #000000;
  font-size: 0.938vw;
  line-height: 1.302vw;
  letter-spacing: 0px;
  margin-bottom: 15px;
  opacity: 1;
  padding: 0.625vw 3.958vw 0.625vw 1.042vw;
  text-align: left;
  width: 22.917vw;
  outline: 0;

  @media (max-width: 992px) {
    padding: 12px 78px 12px 22px;
    font-size: 18px;
    line-height: 25px;
    width: 100%;
  }
`;

const NewsletterSubmit = styled.button`
  align-items: center;
  background-color: #f88436;
  border: none;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  justify-content: center;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  height: 2.552vw;
  width: 2.917vw;
  transition: 0.3s ease;
  outline: 0;

  > div {
    background-color: #2e1c70;
    position: absolute;
    height: 0.156vw;
    width: 0.625vw;
  }

  > div:first-child {
    margin-bottom: 0.3515vw;
    transform: rotate(45deg);
  }

  > div:last-child {
    margin-top: 0.3515vw;
    transform: rotate(-45deg);
  }

  :hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {
    height: 49px;
    width: 56px;

    > div {
      height: 12.34px;
    }

    > div:first-child {
      margin-bottom: -7.17px;
    }

    > div:last-child {
      margin-top: -7.17px;
    }
  }
`;

const SubWrapper = styled.div`
  background-color: #000;
  padding: 0.781vw 0;

  a {
    color: #f88436;
    text-decoration: none;
    transition: 0.3s ease;

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 992px) {
    font-size: 9px;
    line-height: 30px;

    > .content {
      flex-direction: column;
    }

    > .content > div {
      place-self: center;
    }
  }
`;

export default function Footer(props) {
  const handleToken = (token) => {};

  const PartnersLogos = [
    {
      mobile: require("../../Assets/Images/iap.png"),
      desktop: require("../../Assets/Images/iap@2x.png"),
      alt: "Partner IAP Logo",
      link: "https://www.iapcentral.org/",
      width: "",
    },
    {
      mobile: require("../../Assets/Images/logo_esp-white.png"),
      desktop: require("../../Assets/Images/logo_esp-white@2x.png"),
      alt: "Partner ESP Logo",
      link: "https://www.esp-pathology.org/",
      width: "",
    },
    {
      mobile: require("../../Assets/Images/img-rodape-parceiros-02.png"),
      desktop: require("../../Assets/Images/img-rodape-parceiros-02@2x.png"),
      alt: "Partner Id BRCA Logo",
      link: "https://www.idbrca.com/ovarian-home.html",
      width: "",
    },
    {
      mobile: require("../../Assets/Images/img-rodape-parceiros-03.png"),
      desktop: require("../../Assets/Images/img-rodape-parceiros-03@2x.png"),
      alt: "Partner PD L1 Logo",
      link: "https://www.idbrca.com/prostate-home.html",
      width: "",
    },
    {
      mobile: require("../../Assets/Images/img-rodape-parceiros-04.png"),
      desktop: require("../../Assets/Images/img-rodape-parceiros-04@2x.png"),
      alt: "Partner ONTRK Logo",
      link: "https://ontrk.spap.phormuladev.com/",
      width: "",
    },
    {
      mobile: require("../../Assets/Images/Footer/Leica.jpg"),
      desktop: require("../../Assets/Images/Footer/Leica@2x.jpg"),
      alt: "Leica Biosystems",
      link: "https://www.leicabiosystems.com/",
      width: "",
    },
  ];

  const ProjectsLogos = [
    {
      mobile: require("../../Assets/Images/img-rodape-projetos-01.png"),
      desktop: require("../../Assets/Images/img-rodape-projetos-01@2x.png"),
      alt: "",
      width: "",
    },
    {
      mobile: require("../../Assets/Images/img-rodape-projetos-02.png"),
      desktop: require("../../Assets/Images/img-rodape-projetos-02@2x.png"),
      alt: "",
      width: "",
    },
    {
      mobile: require("../../Assets/Images/img-rodape-projetos-03.png"),
      desktop: require("../../Assets/Images/img-rodape-projetos-03@2x.png"),
      alt: "",
      width: "",
    },
  ];

  const PublicityLogos = [
    {
      mobile: require("../../Assets/Images/Merek-Serono.png"),
      desktop: require("../../Assets/Images/Merek-Serono@2x.png"),
      alt: "Publicity Merek Serono Logo",
      link: "https://www.merckgroup.com/",
      width: "11.458vw",
    },
  ];

  const SocialLinks = [
    {
      link: "https://www.instagram.com/nucleointernosdaspap/",
      mobile: require("../../Assets/Images/Icon-social-instagram-botton.png"),
      desktop: require("../../Assets/Images/Icon-social-instagram-botton@2x.png"),
      alt: "Instagram Icon",
      width: "1.620vw",
    },
    /*
    #TODO Adicionar todas redes sociais
    {
      'link' : '#',
      'mobile' : require('../../Assets/Images/Icon-social-facebook-botton.png'),
      'desktop' : require('../../Assets/Images/Icon-social-facebook-botton@2x.png'),
      'alt' : 'Facebook Icon',
      'width' : '1.823vw'
    },
    */
    {
      link: "https://twitter.com/SPAPatologia",
      mobile: require("../../Assets/Images/Icon-social-twitter-botton.png"),
      desktop: require("../../Assets/Images/Icon-social-twitter-botton@2x.png"),
      alt: "Twitter Icon",
      width: "1.823vw",
    },
    /*
    {
      'link' : '#',
      'mobile' : require('../../Assets/Images/Icon-social-youtube-botton.png'),
      'desktop' : require('../../Assets/Images/Icon-social-youtube-botton@2x.png'),
      'alt' : 'Youtube Icon',
      'width' : '2vw'
    }
    */
  ];

  const [newsletterEmail, setNewsletterEmail] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleNewsletter = (e) => {
    e.preventDefault();

    let email = e.target.newsletter_mail.value;

    axios
      .post("https://api.spap.pt/api/set-newsletter", {
        email: email,
      })
      .then((res) => {
        if (res.status == 200) {
          document.getElementById("newsletter-form").reset();
          setModalOpen(true);
          setTimeout(() => {
            setModalOpen(false);
          }, 3000);
          e.target.newsletter_mail.value = "";
          e.target.confirm_terms.checked = false;
        }
      })
      .catch((error) => {
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
      });
  };

  return (
    <>
      <Wrapper>
        <ScrollToTop smooth top={200} component={<SvgUp />} viewBox={0} />
        <Container className="content">
          <FlexInRow>
            <Title>SPAP / PARCERIAS E PROJETOS</Title>
            <FlexInColumn gap="10px" mobile="column" className="img-parceiros">
              <div className="grid">
                {PartnersLogos.map((item, index) => {
                  return (
                    (PartnersLogos.length % 2 === 0 ||
                      index + 1 < PartnersLogos.length) && (
                      <a
                        key={"partnerLogo" + index}
                        href={item.link}
                        target="_blank"
                      >
                        <picture className={"partner-" + (index + 1)}>
                          <source
                            srcSet={item.desktop}
                            media="(min-width: 990px)"
                          />
                          <Logo
                            src={item.mobile}
                            alt={item.alt}
                            width={item.width}
                          />
                        </picture>
                      </a>
                    )
                  );
                })}
              </div>
              {/*
                (PartnersLogos.length % 2 !== 0) &&
                (PartnersLogos[PartnersLogos.length-1].link) ?
                  <a href={PartnersLogos[PartnersLogos.length-1].link} target='_blank'>
                    <picture className={'partner-' + (PartnersLogos.length)}>
                      <source srcSet={PartnersLogos[PartnersLogos.length-1].desktop} media="(min-width: 990px)" />
                      <Logo src={PartnersLogos[PartnersLogos.length-1].mobile} alt={PartnersLogos[PartnersLogos.length-1].alt} width={PartnersLogos[PartnersLogos.length-1].width} />
                    </picture>
                  </a>
                :
                <picture className={'partner-' + (PartnersLogos.length)}>
                      <source srcSet={PartnersLogos[PartnersLogos.length-1].desktop} media="(min-width: 990px)" />
                      <Logo src={PartnersLogos[PartnersLogos.length-1].mobile} alt={PartnersLogos[PartnersLogos.length-1].alt} width={PartnersLogos[PartnersLogos.length-1].width} />
                    </picture>
                    */}
            </FlexInColumn>
          </FlexInRow>
          <FlexInRow>
            <Title right key={"titlePublicitario"}>
              PUBLICITÁRIO
            </Title>
            <FlexInColumn gap="1.302vw" mobile="column">
              {PublicityLogos.map((item, index) => {
                return (
                  <a href={item.link} target="_blank">
                    <picture className={"partner-" + (index + 1)}>
                      <source
                        srcSet={item.desktop}
                        media="(min-width: 990px)"
                      />
                      <Logo
                        src={item.mobile}
                        alt={item.alt}
                        width={item.width}
                      />
                    </picture>
                  </a>
                );
              })}
            </FlexInColumn>
          </FlexInRow>
        </Container>

        <Container>
          <HrInRow />
        </Container>

        <Container className="content">
          <FlexInColumn gap="5.990vw" mobile="column" className="flex-links">
            <nav>
              <Title>ÁREAS DO SITE</Title>
              <List>
                <li key={"footerSpap"}>
                  <Link to="/spap">SPAP</Link>
                </li>
                <li key={"footerSocios"}>
                  <Link to="/socios">Sócios</Link>
                </li>
                <li key={"footerProfissionais"}>
                  <Link to="/ferramentas-uteis">Profissionais de saúde</Link>
                </li>
                <li key={"footerAgenda"}>
                  <Link to="/agenda">Agenda</Link>
                </li>
                <li key={"footerNispap"}>
                  <Link to="/nispap">Núcleo de internos</Link>
                </li>
              </List>
            </nav>
            <nav>
              <Title>Sócio</Title>
              <List>
                <li>
                  <Link to="/socios/area-de-socio">A sua conta</Link>
                </li>
                <li>
                  <Link to="/socios/mensagens">Mensagens</Link>
                </li>
                <li>
                  <Link to="/socios/dados-de-perfil/">Dados de perfil</Link>
                </li>
                <li>
                  <Link to="/socios/quotas">Quotas</Link>
                </li>
              </List>
            </nav>
            <nav>
              <Title>Contactos</Title>
              <List>
                <li>
                  <a href="mailto:geral@spap.phormuladev.com" rel="noreferrer">
                    geral@spap.phormuladev.com
                  </a>
                </li>
                <li>
                  <a href="mailto:site@spap.phormuladev.com" rel="noreferrer">
                    site@spap.phormuladev.com
                  </a>
                </li>
                <li>
                  <a href="mailto:socios@spap.phormuladev.com" rel="noreferrer">
                    socios@spap.phormuladev.com
                  </a>
                </li>
              </List>
            </nav>
            <FlexInRow placeself="flex-end" mobile={true}>
              <FlexInColumn gap="30px">
                {SocialLinks.map((item, index) => {
                  return (
                    <a href={item.link} target="_blank" className="social-icon">
                      <picture className={"partner-" + (index + 1)}>
                        <source
                          srcSet={item.desktop}
                          media="(min-width: 990px)"
                        />
                        <Logo
                          src={item.mobile}
                          alt={item.alt}
                          width={item.width}
                        />
                      </picture>
                    </a>
                  );
                })}
              </FlexInColumn>
            </FlexInRow>
          </FlexInColumn>

          <HrInColumn />

          <FlexInRow>
            <Title>SUBSCREVA A NEWSLETTER DA SPAP</Title>
            <Newsletter
              onSubmit={(e) => handleNewsletter(e)}
              id="newsletter-form"
            >
              <NewsletterEmail
                type="email"
                name="newsletter_mail"
                placeholder="Endereço de e-mail"
                onChange={(e) => setNewsletterEmail(e.target.value)}
                required
              />
              <NewsletterSubmit name="newsletter_submit">
                <div />
                <div />
              </NewsletterSubmit>
              <div>
                <Recaptcha
                  sitekey="6Lcfpy8mAAAAAMsUWvFiFerTMe9IdnDZP5KljRUu"
                  onToken={handleToken}
                />
                <input
                  type="checkbox"
                  name="confirm_terms"
                  value="true"
                  required
                />
                <span>
                  Concordo com os{" "}
                  <Link to="/termos-e-condicoes" target="_blank">
                    termos e condições
                  </Link>{" "}
                  presentes no site.
                </span>
              </div>
            </Newsletter>
          </FlexInRow>
        </Container>

        <FooterCards />

        <SubWrapper>
          <Container className="content">
            <div>
              <span>SPAP © Copyright 2023</span>
            </div>
            <div>
              <span>
                Todos os direitos reservados |{" "}
                <Link to="/termos-e-condicoes">Termos e condições</Link> |{" "}
                <Link to="/politica-de-privacidade">
                  Política de privacidade & Proteção dos dados
                </Link>
              </span>
            </div>
          </Container>
        </SubWrapper>

        <Modal
          centered
          open={successModalOpen}
          footer={null}
          onCancel={() => setModalOpen(false)}
        >
          <Result
            style={{ background: "#fff" }}
            status="success"
            title="Subscreveu a nossa newsltter!"
            subTitle="Obrigado por subscrever a newsletter, fique atento!"
          />
        </Modal>
        <Modal
          centered
          open={errorModalOpen}
          footer={null}
          onCancel={() => setErrorModalOpen(false)}
        >
          <Result
            style={{ background: "#fff" }}
            title="Subscreveu a nossa newsletter anteriormente."
            subTitle={"Olá, esteja atento às novidades!"}
          />
        </Modal>
      </Wrapper>
    </>
  );
}
