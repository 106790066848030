import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Button, Dropdown, Space, Avatar } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCartOutlined } from "@ant-design/icons";
import EventShoppingCart from "../EventShoppingCart";
import MessagesInbox from "../../Assets/Images/Messages.svg";
import axios, { all } from "axios";
import NotificationSound from "../../Assets/Sounds/tap-notification.mp3";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .count-items {
    background: rgb(248, 132, 54);
    color: #fff;
    border-radius: 100px;
    padding: 2px;
    position: absolute;
    font-size: 9px;
    height: 15px;
    width: 15px !important;
    line-height: 10px;
    position: absolute;
    bottom: 0;
    right: -5px;
  }

  .absolute-msg {
    bottom: -5px;
    left: 0;
  }

  .header__cta {
    background-color: #f88436;
    border-radius: 27px;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 42px;
    opacity: 1;
    display: block;
    padding: 0px 30px;
    height: 42px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s ease;

    :hover {
      background-color: #e87426;
      color: #fff !important;
    }
  }

  .cart-icon {
    font-size: 20px;
  }

  .inbox-div {
    border-radius: 100px;
    position: relative;
    bordar: 1px solid #fff !important;
    width: 42px;
    height: 42px;
    border: 1px solid #fff;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;f
  }

  .inbox-div > img {
    width: 50%;
    height: 50%;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 5px 10px !important;
  }
  .ant-dropdown-menu .ant-dropdown-menu-title-content > a {
    text-decoration: underline !important;
    text-align: center !important;
  }

  .btn-dropdown {
    background-color: transparent;
    border: 1px solid #fff !important;
    border-radius: 27px;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: inherit;
    opacity: 1;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;

    border: none;

    :hover {
      color: #494d7d !important;
      background: #fff;
      border: none;
    }
  }

  .ant-btn {
    height: auto;
  }

  #userProfileIcon {
    color: #f88436;
    font-size: 6.111vw;
  }


    .g-20 {
      gap: 20px !important;
    }

  @media (max-width: 600px) {
    .g-20 {
      flex-direction: row-reverse;
      gap: 5px !important;
    }
  }

  @media (min-width: 1920px) {
    .header__cta,
    .btn-dropdown {
      border-radius: 27px;
      font-size: 18px;
      line-height: 26px;
      padding: 8px 30px;
      width: 100%;
    }
  }
`;

export default function Container(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const audioPlayer = useRef(null);
  const [audioElement, setAudioElement] = useState();
  const [canPlay, setCanPlay] = useState(false);
  const [pendingMsg, setPendingMsg] = useState(
    localStorage.getItem("pendingMsg") ?? 0
  );
  useEffect(() => {
    audioElement?.addEventListener("canplaythrough", (event) => {
      setCanPlay(true);
    });
  }, [audioElement]);
  useEffect(() => {
    setAudioElement(new Audio(NotificationSound));
  }, []);

  const AudioStartClick = () => {
    if (!canPlay) return;
    audioElement.play();
  };
  // stop
  const AudioStopClick = () => {
    if (!canPlay) return;
    audioElement.pause();
  };
  const items = [
    {
      key: "1",
      label: <Link to="/socios/dados-de-perfil/">Ver Perfil</Link>,
    },
    {
      key: "2",
      label: <Link to="/logout/">Logout</Link>,
    },
  ];

  const countNotReadMessages = (messages) => {
    let count = 0;
    messages.map((message) => {
      if (
        message.showNew == 1 &&
        message.to_user == session_storage.all_data.user_session.id
      ) {
        count += 1;
      }
    });

    if (count == 0) {
      window.localStorage.removeItem("pendingMsg");
      return;
    }

    window.localStorage.setItem("pendingMsg", count);
    window.dispatchEvent(new Event("storage"));
  };
  const handleMessages = (messages, allUsers) => {
    messages = messages.filter((msg) => {
      if (!msg.deleted_by) return msg;
      if (
        !JSON.parse(msg.deleted_by).includes(
          session_storage.all_data.user_session.id
        )
      )
        return msg;
    });
    messages.forEach((message) => {
      let otherUser =
        message.to_user != session_storage.all_data.user_session.id
          ? message.to_user
          : message.from_user;
      message.subjectName =
        session_storage.all_data.user_session.id +
        "-" +
        otherUser +
        "-" +
        message.subject;
    });
    let myMessages = Object.groupBy(messages, ({ subjectName }) => [
      subjectName,
    ]);
    let arrayNames = Object.keys(myMessages);
    let listMessages = [];

    arrayNames.forEach((array) => {
      let thread = myMessages[array];
      let sorted = thread.sort((b, a) => b.id - a.id)[
        myMessages[array].length - 1
      ];
      let firstCreated = thread.sort((a, b) =>
        a.created_at.localeCompare(b.created_at)
      )[0].created_at;
      let myUserId = session_storage.all_data.user_session.id;
      let userId =
        sorted.from_user == myUserId ? sorted.to_user : sorted.from_user;

      let other = allUsers?.filter((user) => {
        return user.id == userId;
      });

      if (other?.length > 0) {
        sorted.userName = other[0].nome_completo;
        let showNewMsg = 0;
        if (
          sorted.is_read == 0 &&
          sorted.to_user == session_storage.all_data.user_session.id
        ) {
          showNewMsg = 1;
        }
        listMessages.push({
          from_user: sorted.from_user,
          to_user: sorted.to_user,
          subject: sorted.subject,
          name: other[0].nome_completo,
          avatar: other[0].avatar,
          message: sorted.message,
          created: new Date(firstCreated).toLocaleString().split(",")[0],
          lastDate: new Date(sorted.updated_at).toLocaleString().split(",")[0],
          lastTime: new Date(sorted.updated_at).toLocaleString().split(",")[1],
          subjectName: array,
          showNew: showNewMsg,
          updated_at: sorted.updated_at,
          deleted_by: sorted.deleted_by,
        });
      }
    });

    countNotReadMessages(listMessages);
  };

  useEffect(() => {
    const handleStorage = () => {
      window.addEventListener("storage", () => {
        setPendingMsg(window.localStorage.getItem("pendingMsg") ?? 0);
      });
    };

    window.addEventListener("storage", handleStorage());

    var pusher = new Pusher("bce938f1989b6fee1ff4", {
      cluster: "eu",
    });

    var channel = pusher.subscribe("my-channel");
    channel.bind("my-event", function (data) {
      if (
        data.from_user != session_storage.all_data.user_session.id &&
        data.to_user == session_storage.all_data.user_session.id
      ) {
        window.dispatchEvent(new Event("storage"));
        axios
          .get(
            "https://api.spap.pt/api/messages/" +
              session_storage.all_data.user_session.id
          )
          .then((response) => {
            AudioStartClick();
            handleMessages(response.data.messages, response.data.users);
          });
      }
    });

    return () => window.removeEventListener("storage", handleStorage());
  }, []);

  return (
    <Wrapper className={props.className}>
      {session_storage == null &&
        (!props.mobile ? (
          <Space className="g-20">
            <div className="hide-event">
              <div className="inbox-div">
                <ShoppingCartOutlined
                  className="cart-icon"
                  onClick={() => {
                    setOpenShoppingCart(true);
                  }}
                />
                {localStorage.getItem("countCartItems") != null &&
                  JSON.parse(localStorage.getItem("countCartItems")) > 0 && (
                    <span className="count-items ">
                      {JSON.parse(localStorage.getItem("countCartItems"))}
                    </span>
                  )}
              </div>
            </div>
            <Link to="/login" className="header__cta">
              Entrar
            </Link>
          </Space>
        ) : (
          <Link to="/login" id="userProfileIcon">
            <FontAwesomeIcon icon={faUser} />
          </Link>
        ))}

      {session_storage && session_storage.all_data.role == "admin" && (
        <>
          <Space className="g-20">
            <div className="hide-event">
              <div className="inbox-div">
                <ShoppingCartOutlined
                  className="cart-icon"
                  onClick={() => {
                    setOpenShoppingCart(true);
                  }}
                />
                {localStorage.getItem("countCartItems") != null &&
                  JSON.parse(localStorage.getItem("countCartItems")) > 0 && (
                    <span className="count-items ">
                      {JSON.parse(localStorage.getItem("countCartItems"))}
                    </span>
                  )}
              </div>
            </div>
            <Dropdown
              className="dropdown-header d-desktop"
              menu={{
                items,
              }}
              placement="bottom"
              arrow
              overlayClassName="dropdown-header-menu"
            >
              <Button className="btn-dropdown">
                Olá, {session_storage.all_data.user_session.name.split(" ")[0]}{" "}
              </Button>
            </Dropdown>
            <Link className="msg" to="/socios/mensagens">
              <div className="inbox-div">
                <img src={MessagesInbox} />
                {window.localStorage.getItem("pendingMsg") != null &&
                  window.localStorage.getItem("pendingMsg") > 0 && (
                    <span className="count-items absolute-msg">
                      {window.localStorage.getItem("pendingMsg")}
                    </span>
                  )}
              </div>
            </Link>
            <Link className="msg d-mobile" to="/socios/dados-de-perfil">
              <Avatar
                className=" mr-5"
                size={42}
                src={
                  "https://api.spap.pt/avatars/" +
                  session_storage.all_data.user_session.avatar
                }
              />
            </Link>
          </Space>
        </>
      )}

      {session_storage && session_storage.all_data.role != "admin" && (
        <>
          <Space className="g-20">
            <div className="hide-event">
              <div className="inbox-div">
                <ShoppingCartOutlined
                  className="cart-icon"
                  onClick={() => {
                    setOpenShoppingCart(true);
                  }}
                />
                {localStorage.getItem("countCartItems") != null &&
                  JSON.parse(localStorage.getItem("countCartItems")) > 0 && (
                    <span className="count-items ">
                      {JSON.parse(localStorage.getItem("countCartItems"))}
                    </span>
                  )}
              </div>
            </div>
            <Dropdown
              className="dropdown-header d-desktop"
              menu={{
                items,
              }}
              placement="bottom"
              arrow
              overlayClassName="dropdown-header-menu"
            >
              <Button className="btn-dropdown">
                Olá, {session_storage.all_data.user_session.name.split(" ")[0]}{" "}
              </Button>
            </Dropdown>
            <Link className="msg" to="/socios/mensagens">
              <div className="inbox-div">
                <img src={MessagesInbox} />
                {window.localStorage.getItem("pendingMsg") != null &&
                  window.localStorage.getItem("pendingMsg") > 0 && (
                    <span className="count-items absolute-msg">
                      {window.localStorage.getItem("pendingMsg")}
                    </span>
                  )}
              </div>
            </Link>
            <Link className="msg d-mobile" to="/socios/dados-de-perfil">
              <Avatar
                className=" mr-5"
                size={42}
                src={
                  "https://api.spap.pt/avatars/" +
                  session_storage.all_data.user_session.avatar
                }
              />
            </Link>
          </Space>
        </>
      )}

      <EventShoppingCart
        show={openShoppingCart}
        onChange={(e) => setOpenShoppingCart(e)}
      />
    </Wrapper>
  );
}
