import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { Helmet } from "react-helmet";
import { ReactComponent as ReactLogo } from "../../../Assets/Images/icon-awesome-file-alt.svg";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionCategories = styled.section`
  padding: 2.292vw 0 0;
`;

const CategoriesNav = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2.083vw;

  .item {
    align-items: center;
    background-color: #c2c4d4;
    border-radius: 1.042vw 1.042vw 0 0;
    color: #8688a8;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    transition: 0.3s ease;
    cursor: pointer;
    padding: 5px;

    picture {
      width: 130px;

      img {
        width: 100%;
      }
    }
  }

  .item:hover {
    box-shadow: 0px -8px 20px #00000025;
  }

  .item-${(props) => props.itemOffset} {
    background-color: #d6d7e2;
    color: #494d7d;
  }

  @media (max-width: 992px) {
    .item {
      font-size: 14px;
      line-height: 18px;
    }

    .item picture {
      display: none;
    }
  }

  @media (min-width: 1920px) {
    gap: 48px;

    .item picture {
      gap: 0;
    }
  }
`;

const CategoriesContent = styled.div`
  background-color: #d6d7e2;
  padding: 3.125vw 0;

  .item {
    display: none;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease;
  }

  .item-${(props) => props.itemOffset} {
    display: flex;
    opacity: 1;
    pointer-events: auto;
  }

  .item-1 picture {
    max-width: 30.417vw;
  }

  @media (max-width: 992px) {
    .item-1 picture {
      max-width: 80%;
    }

    .item-2 picture {
      max-width: 60%;
    }
  }

  @media (min-width: 1920px) {
    .grid-cards {
      gap: 48px;
    }
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border: 0.156vw solid #8688a8;
  border-top: 0;
  border-radius: 0 0 1.042vw 1.042vw;
  display: flex;
  flex-direction: column;
  width: 100%;

  > .title {
    background-color: #494d7d;
    color: #ffffff;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    padding: 0.677vw 1.042vw;
    margin: 0 -0.078vw;
  }

  > .content {
    color: #494d7d;
    padding: 1.302vw;
  }

  > .content p {
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
  }

  > .content p a {
    color: #f88436;
    text-decoration: none;
    justify-content: flex-start;
    transition: 0.3s ease-out;
  }

  > .content p a:hover {
    text-decoration: underline;
  }

  > .bar {
    background-color: #494d7d;
    height: 0.521vw;
  }

  ${(props) =>
    props.backgroundImage &&
    `
    background-image: url(${props.backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `}

  ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }

  li,
  li a {
    color: #494d7d;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 24px;
    text-align: left;
    text-decoration: none;
    justify-content: flex-start;
    transition: 0.3s ease-out;
  }

  li a:hover {
    text-decoration: underline;
  }

  .icon {
    margin-right: 10px;
  }

  .icon-file {
    height: auto;
    min-width: 18px;
    max-width: 18px;
  }

  .icon-file path {
    fill: #494d7d;
  }

  @media (max-width: 992px) {
    > .title {
      font-size: 18px;
      line-height: 22px;
    }

    li,
    li a,
    > .content p {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    .icon-file {
      min-width: 12px;
      max-width: 12px;
    }
  }
`;

export default function Guidelines(props) {
  const [itemOffset, setItemOffset] = useState(0);

  return (
    <Wrapper>
      <Helmet>
        <title>
          Guidelines | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Anatomia Patológica, Nacionais | Anatomia Patológica, Internacionais | Clínicas, Nacionais"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Guidelines"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Guidelines",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-top-guidelines@2x.png")}
          imageMobile={require("../../../Assets/Images/img-top-guidelines.png")}
          imageAlt="Guidelines"
        />
      </Container>

      <SectionCategories>
        <Container>
          <CategoriesNav itemOffset={itemOffset}>
            <div className="item item-0" onClick={(e) => setItemOffset(0)}>
              {itemOffset === 0 ? (
                <picture>
                  <source
                    srcSet={require("../../../Assets/Images/guideline01@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../Assets/Images/guideline01.png")}
                    alt="College of American Pathlogists"
                  />
                </picture>
              ) : (
                <picture>
                  <source
                    srcSet={require("../../../Assets/Images/guideline01-off@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../Assets/Images/guideline01-off.png")}
                    alt="College of American Pathlogists"
                  />
                </picture>
              )}
              Anatomia Patológica, Nacionais
            </div>
            <div className="item item-1" onClick={(e) => setItemOffset(1)}>
              {itemOffset === 1 ? (
                <picture>
                  <source
                    srcSet={require("../../../Assets/Images/guideline02@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../Assets/Images/guideline02.png")}
                    alt="College of American Pathlogists"
                  />
                </picture>
              ) : (
                <picture>
                  <source
                    srcSet={require("../../../Assets/Images/guideline02-off@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../Assets/Images/guideline02-off.png")}
                    alt="College of American Pathlogists"
                  />
                </picture>
              )}
              Anatomia Patológica, Internacionais
            </div>
            <div className="item item-2" onClick={(e) => setItemOffset(2)}>
              {itemOffset === 2 ? (
                <picture>
                  <source
                    srcSet={require("../../../Assets/Images/guideline03@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../Assets/Images/guideline03.png")}
                    alt="College of American Pathlogists"
                  />
                </picture>
              ) : (
                <picture>
                  <source
                    srcSet={require("../../../Assets/Images/guideline03-off@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../../Assets/Images/guideline03-off.png")}
                    alt="College of American Pathlogists"
                  />
                </picture>
              )}
              Clínicas, Nacionais
            </div>
          </CategoriesNav>
        </Container>
        <CategoriesContent itemOffset={itemOffset}>
          <Container>
            <div className="item item-0">
              <FlexInColumn
                gap="2.604vw"
                className="grid-cards"
                mobile="column"
              >
                <Card>
                  <h3 className="title">Versões Actuais</h3>
                  <div className="content">
                    {/*
                    <picture>
                      <source srcSet={require('../../../Assets/Images/img-guidelines-coming-soon@2x.png')} media="(min-width: 990px)" />
                      <img src={require('../../../Assets/Images/img-guidelines-coming-soon.png')} alt="Coming soon" />
                    </picture>
                    */}
                    <ul>
                      <li>
                        <a
                          href="https://api.spap.pt/ficheiros/19/ficheiro-1684246099.pdf"
                          target="_blank"
                        >
                          <ReactLogo
                            alt="Icon File"
                            className="icon icon-file"
                          />
                          Diagnóstico e Orientação Terapêutica no Carcinoma Não
                          Pequenas Células do Pulmão
                        </a>
                      </li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <h3 className="title">Em revisão externa, participe!</h3>
                  <div className="content">
                    <ul>
                      {/*
                      <li>
                        <a href="https://api.spap.pt/ficheiros/13/ficheiro-1683649898.pdf" target='_blank'>
                          <ReactLogo alt="Icon File" className="icon icon-file" />Tumores Glândulas Salivares
                        </a>
                      </li>
                      <li>
                        <a href="https://api.spap.pt/ficheiros/12/ficheiro-1683649870.pdf" target='_blank'>
                          <ReactLogo alt="Icon File" className="icon icon-file" />NETS
                        </a>
                      </li>
                      <li>
                        <a href="https://api.spap.pt/ficheiros/11/ficheiro-1683649845.pdf" target='_blank'>
                          <ReactLogo alt="Icon File" className="icon icon-file" />Cancro da Próstata
                        </a>
                      </li>
                      <li>
                        <a href="https://api.spap.pt/ficheiros/10/ficheiro-1683649817.pdf" target='_blank'>
                          <ReactLogo alt="Icon File" className="icon icon-file" />Colo do Útero
                        </a>
                      </li>
                      <li>
                        <a href="https://api.spap.pt/ficheiros/9/ficheiro-1683649781.pdf" target='_blank'>
                          <ReactLogo alt="Icon File" className="icon icon-file" />Tumores do Ovário
                        </a>
                      </li>
                      <li>
                        <a href="https://api.spap.pt/ficheiros/8/ficheiro-1683649736.pdf" target='_blank'>
                          <ReactLogo alt="Icon File" className="icon icon-file" />GIST
                        </a>
                      </li>
                      <li>
                        <a href="https://api.spap.pt/ficheiros/7/ficheiro-1683649698.pdf" target='_blank'>
                          <ReactLogo alt="Icon File" className="icon icon-file" />Linfomas
                        </a>
                      </li>
                      <li>
                        <a href="https://api.spap.pt/ficheiros/6/ficheiro-1683649624.pdf" target='_blank'>
                          <ReactLogo alt="Icon File" className="icon icon-file" />Tumores do Esófago
                        </a>
                      </li>
                    */}
                      <li>
                        <a
                          href="https://api.spap.pt/ficheiros/109/ficheiro-1685004974.pdf"
                          target="_blank"
                        >
                          <ReactLogo
                            alt="Icon File"
                            className="icon icon-file"
                          />
                          Protocolo Cancro do Endométrio
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://api.spap.pt/ficheiros/18/ficheiro-1684245944.pdf"
                          target="_blank"
                        >
                          <ReactLogo
                            alt="Icon File"
                            className="icon icon-file"
                          />
                          Protocolo Cancro da Vulva
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://api.spap.pt/ficheiros/101/ficheiro-1684743394.pdf"
                          target="_blank"
                        >
                          <ReactLogo
                            alt="Icon File"
                            className="icon icon-file"
                          />
                          Protocolo Cancro do colo do útero
                        </a>
                      </li>
                    </ul>
                  </div>
                </Card>
                <Card
                  backgroundImage={require("../../../Assets/Images/bg-guidelines-ideia@2x.png")}
                >
                  <div className="bar"></div>
                  <div className="content">
                    <p>
                      Tem uma ideia, iniciativa para uma guideline /
                      recomendação / protocolo??
                    </p>
                    <p>
                      Nós queremos ajudar, contacte-nos!{" "}
                      <a href="mailto:socios@spap.phormuladev.com">
                        socios@spap.phormuladev.com
                      </a>
                    </p>
                  </div>
                </Card>
              </FlexInColumn>
            </div>
            <div className="item item-1">
              <FlexInRow gap="1.302vw">
                <FlexInColumn gap="1.302vw" mobile="column">
                  <a
                    href="https://www.cap.org/protocols-and-guidelines/cancer-reporting-tools/cancer-protocol-templates"
                    target="_blank"
                  >
                    <picture>
                      <source
                        srcSet={require("../../../Assets/Images/img-link-guidelines-college-of-american-pathologists@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/img-link-guidelines-college-of-american-pathologists.png")}
                        alt="College of American Pathlogists"
                      />
                    </picture>
                  </a>
                  <a
                    href="https://www.rcpath.org/profession/guidelines.html"
                    target="_blank"
                  >
                    <picture>
                      <source
                        srcSet={require("../../../Assets/Images/img-link-guidelines-the-royal-ccollege-of-pathlogists@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/img-link-guidelines-the-royal-ccollege-of-pathlogists.png")}
                        alt="The Royal College of Pathlogists"
                      />
                    </picture>
                  </a>
                </FlexInColumn>
                <FlexInColumn gap="1.302vw" mobile="column">
                  <a
                    href="https://www.iccr-cancer.org/datasets/published-datasets/"
                    target="_blank"
                  >
                    <picture>
                      <source
                        srcSet={require("../../../Assets/Images/img-link-guidelines-iccr@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/img-link-guidelines-iccr.png")}
                        alt="ICCR"
                      />
                    </picture>
                  </a>
                  <a
                    href="https://www.rcpath.org/profession/guidelines.html"
                    target="_blank"
                  >
                    <picture>
                      <source
                        srcSet={require("../../../Assets/Images/img-link-guidelines-rcpa@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../Assets/Images/img-link-guidelines-rcpa.png")}
                        alt="RCPA"
                      />
                    </picture>
                  </a>
                </FlexInColumn>
              </FlexInRow>
            </div>
            <div className="item item-2">
              <FlexInColumn gap="0.781vw" mobile="column">
                <a
                  href="https://api.spap.pt/ficheiros/14/ficheiro-1684244642.pdf"
                  target="_blank"
                >
                  <picture>
                    <source
                      srcSet={require("../../../Assets/Images/Oncologia@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../Assets/Images/Oncologia.png")}
                      alt="Oncologia"
                    />
                  </picture>
                </a>
                <a
                  href="https://api.spap.pt/ficheiros/15/ficheiro-1684245097.pdf"
                  target="_blank"
                >
                  <picture>
                    <source
                      srcSet={require("../../../Assets/Images/Cancro-Ginecologico@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../Assets/Images/Cancro-Ginecologico.png")}
                      alt="Cancro Ginecologicco"
                    />
                  </picture>
                </a>
                <a
                  href="https://api.spap.pt/ficheiros/16/ficheiro-1684245244.pdf"
                  target="_blank"
                >
                  <picture>
                    <source
                      srcSet={require("../../../Assets/Images/Concenso-Nacional-Cancro-da-Mama@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../../Assets/Images/Concenso-Nacional-Cancro-da-Mama.png")}
                      alt="IX Consenso Nacional de Cancro da Mama"
                    />
                  </picture>
                </a>
              </FlexInColumn>
            </div>
          </Container>
        </CategoriesContent>
      </SectionCategories>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
